import React, { Fragment } from "react";  
import axios from "axios";
import {
  allMicrositeUrl,
  baseUrl,
  commonUserUrl,
  loginUrl,
  micrositeDetailsUrl,
} from "../../api/Api";
import logo from "../../asset/image/lms.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import noImageNound from "../../asset/image/noImage.jpg";
import ComBanner from "../../asset/image/BannerC.jpg";
import MainLoader from "../../components/PageLoadEffects/MainLoader";
import { useParams } from 'react-router-dom';   
import { useState } from "react";
import { useEffect } from "react"; 
import MsDetailsBody from "../../components/RegisterBody/MsDetailsBody";

const CommunityDetails = () => {
  const { uuid } = useParams(); 
  const [loaderEffect, setLoaderEffect] = useState(true);
  const [msDetails, setMsDetails] = useState(null);

  useEffect(() => {
    // Clear localStorage before making the request to avoid outdated data
    localStorage.removeItem("msDetails");

    let config = {
      method: "get",
      url: `${micrositeDetailsUrl}/${uuid}`,
    };

    axios.request(config)
      .then((response) => { 
        setMsDetails(response.data); // Update state with the fetched data
        setLoaderEffect(false);
      })
      .catch((error) => {
        console.error("Error fetching community details:", error);
        setLoaderEffect(false);
      });
  }, [uuid]); // Dependency array ensures the effect runs when `uuid` changes

  if (loaderEffect) {
    return (
      <Fragment>
         <MainLoader />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <MsDetailsBody msDetails={msDetails} />
    </Fragment>
  );
};

export default CommunityDetails; 

