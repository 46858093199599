import React, { useContext, useState } from "react";
import { Avatar, Box, Button, Card, FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import ItemEffect from "../../Skeleton/VideoBroadcast/ItemEffect";
import { useNavigate } from "react-router-dom";
import PollcastItem from "./PollcastItem";
import { Image } from "antd";

import pollImge from '../../../asset/image/summary.jpg'
import StorageProgress from "../../storageProgress/StorageProgress";
import { baseUrl } from "../../../api/Api";
import PollCardEffect from "../../Skeleton/VideoBroadcast/PollCardEffect";
import ReactReadMoreReadLess from "react-read-more-read-less";

import parser from 'html-react-parser'
import ReadMoreComponent from "../../ReadMoreComponent/ReadMoreComponent";
import { UserContext } from "../../../utils/UserContext";
import PollDropDownMenu from "./PollDropDownMenu";

import styled from 'styled-components';
import Swal from "sweetalert2";
import { useDeletePollMutation } from "../../../api/slices/pollApiSlice";

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const StickyBottomBox = styled(Box)`
  position: sticky;
  bottom: 0; 
  background-color: white; 
  padding: 5px; 
  marginTop:20px;
`;


const PollcastItemSection = ({pollItem, loading}) => {
  const navigate = useNavigate();
  const {userDetails} = useContext(UserContext)
  // Function to check if a given date and time have expired
  function isExpired(dateTimeString) {
    // Parse the date string into a Date object
    const dateTime = new Date(dateTimeString);
    
    // Get the current date and time
    const now = new Date();
    
    // Compare the two dates
    return dateTime < now; // Returns true if the given date and time have expired
  }

  const token = localStorage.getItem('token');
  const [deletePoll] = useDeletePollMutation()


 // handle details item
 const handleSinglItem = (data)=>{
   navigate(`/poll/${data?.uuid}`, {state:{pollDetails:data}})
 }


 const handleDeleteRow = (uuid) => {
  Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  }).then((result) => {
    if (result.isConfirmed) {
     deletePoll({token,uuid})
    }
  });
};
// handle edit item
 const handleEditItem = (data)=>{
   navigate(`/poll-update/${data?.uuid}`, {state:{pollDetails:data}})
}

  return (
    <>
      <div className="broadcast-item-section">
        {/* <h4> Videos </h4> */}
        {loading && <PollCardEffect />} 
        {!loading && (pollItem?.length ===0) &&
          <Box display={'flex'} justifyContent={'center'} justifyItems={'center'}>
            <Button disabled>{'No Poll Found...'}</Button>
          </Box>
         }
         <Grid container spacing={2}>
              {pollItem && pollItem.length>0 && pollItem.map((data, key)=>{
                  const options = { year: "numeric", month: "short", day: "numeric" };
                  const date = data?.created_at?new Date(data?.end_date).toLocaleDateString("en-US", options):''

                  const dateTimeString = data?.end_date.split(' ').join('T');
                  const endDate =  data?.end_date
                  const currentDate = new Date()
                  const isDateTimeExpired = isExpired(dateTimeString);

                  var username = data?.microsite_userdetails?.user_details? JSON.parse(data?.microsite_userdetails?.user_details)?.name?JSON.parse(data?.microsite_userdetails?.user_details)?.name:data?.user?.name:data?.user?.name


                  const date1 = new Date(data?.end_date);
                  const date2 = new Date().toLocaleString().split(',').join(' ')
                  function getDifferenceInDays(date1, date2) {
                    const diffInMs = Math.abs(date2 - date1);
                    return diffInMs / (1000 * 60 * 60 * 24);
                  }
                  
                  function getDifferenceInHours(date1, date2) {
                    const diffInMs = Math.abs(date2 - date1);
                    return diffInMs / (1000 * 60 * 60);
                  }
                  
                  function getDifferenceInMinutes(date1, date2) {
                    const diffInMs = Math.abs(date2 - date1);
                    return diffInMs / (1000 * 60);
                  }

                 

                  var keywords = null;
                  if( data?.meta?.keywords){
                    keywords =  data?.meta?.keywords.split(',')
                  }

                  var avatar = null
                  const parseData = JSON.parse(data?.microsite_userdetails?.user_details)
                  if(parseData?.avater){
                    avatar = parseData?.avater
                  }else{
                    avatar = data?.user?.avatar
                  }

                return(
                  <Grid item lg={4} md={4} sm={12}>
                      <Card sx={{mt:1}} key={data.uuid} className="pollCards" >
                          <Box sx={{m:2}} className='cursorPointer'> 
                              {/* image and title part  */}
                              <Box sx={{mt:2}} className='cardBody' >
                                  <Box display={'flex'} justifyContent={'space-between'}>
                                    <Box><Avatar alt={username} src={`${baseUrl}/${avatar}`} /></Box>
                                    {data?.media && data?.media.length>0  &&
                                    <div className="poll_card_image" onClick={(e) => handleSinglItem(data)}>
                                        {data?.media && data?.media.length>0 &&
                                          <img src={`${baseUrl}/storage/media/${data?.media[0].id}/${data?.media[0].file_name}`} alt="" width={'100%'} height={'100%'} />
                                        }
                                    </div>}
                                    {userDetails?.id === data?.user_id && 
                                      <Box><PollDropDownMenu data={data}  handleDeleteRow={handleDeleteRow} handleEditItem={handleEditItem} /></Box>
                                    }
                                  </Box>  

                                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'} onClick={(e) => handleSinglItem(data)}><Button sx={{ml:1, mb:5, mt:1.5}}>Poll No :{data?.id}</Button></Box>

                                  {keywords !==null &&
                                  <Box sx={{mt:1, mb:1}} display={'flex'} alignItems={'center'} justifyContent={'center'} onClick={(e) => handleSinglItem(data)}>
                                    <Stack direction="row" spacing={1}>
                                    {keywords.slice(0,2).map((keyword, key)=>{
                                      return(
                                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}><Chip label={keyword} size="small" /></Box>
                                      )
                                    })}
                                    {keywords?.length>2 &&
                                      <Box display={'flex'} alignItems={'center'} justifyContent={'center'} ><Chip label={`${keywords?.length -2} ...More`} size="small" /></Box>
                                    }
                                    </Stack>
                                  </Box>}

                                    <Box  display={'flex'} alignItems={'center'} justifyContent={'center'} onClick={(e) => handleSinglItem(data)}>
                                      <ReadMoreComponent text={data?.name} maxLength={90} />
                                    </Box>
                                    <Box  display={'flex'} alignItems={'center'} justifyContent={'center'} onClick={(e) => handleSinglItem(data)}>
                                      {/* <Box><i>Signs/Symptoms: </i> Chest pain with EF 15%.</Box> */}
                                    </Box>
                                    <Box className="sticky_bottom" sx={{mt:5}} display={'flex'} alignItems={'center'} justifyContent={'center'} onClick={(e) => handleSinglItem(data)}>
                                      <Box><small className="smaller"> <i>Created By: {username}</i>  ({isDateTimeExpired?'Expired': `Expires on: ${date}`})</small></Box>
                                    </Box>

                                </Box>
                          </Box>
                      </Card>
                  </Grid>
                )
              })}
         </Grid>
      </div>
    </>
  );
};

export default PollcastItemSection;
