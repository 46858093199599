import React, { Fragment } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,Dialog, DialogTitle, DialogContent, DialogActions
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SyncIcon from '@mui/icons-material/Sync';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { produce } from 'immer';
import { useState } from 'react'; 


const QuestionAnswerForm = ({ questionList, setQuestionList, pollId, handleQuestion, handleDeleteQuestion,handleAnswer }) => {
  
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleQuestionChange = (index) => (event) => {
    const { value } = event.target;
    setQuestionList(produce(draft => {
      draft[index].question_text = value;
    }));
  };

  const handleAnswerChange = (questionIndex, answerIndex) => (event) => {
    const { value } = event.target;
    setQuestionList(produce(draft => {
      draft[questionIndex].answers[answerIndex].answer_text = value;
    }));
  };

  return (
    <Fragment>
      {questionList && questionList.length > 0 && (
        <Box sx={{ mt: 5 }}>
          {questionList.map((question, index) => (
            <Grid container spacing={2} key={index}>
              <Grid sx={{ mb: 1 }} item xs={6}>
                <TextField
                  fullWidth
                  focused
                  className="form_control"
                  label={`Question`}
                  variant="filled"
                  value={question.question_text}
                  onChange={handleQuestionChange(index)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={(e)=>handleQuestion(pollId,question.uuid,question.question_text)}>
                          <SyncIcon />
                        </IconButton>
                        <IconButton onClick={(e)=>handleDeleteQuestion(question.uuid)}>
                          <DeleteForeverIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              {question.answers.map((answer, answerIndex) => (
                <Grid sx={{ mb: 1 }} item xs={2} key={answerIndex}>
                  <TextField
                    fullWidth
                    focused
                    className="form_control"
                    disabled={!question.question_text}
                    label={`Answer ${answerIndex + 1}`}
                    variant="filled"
                    value={answer.answer_text}
                    onChange={handleAnswerChange(index, answerIndex)}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton disabled={!question.question_text || !answer.answer_text} onClick={(e)=> handleAnswer(question?.id,null,answer.uuid,answer.answer_text)}>
                            <SyncIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          ))} 
        </Box>
        
      )}
        <Box sx={{ mt: 5 }}>
          <Button
              className="MuiBtn_create"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleClickOpen}
            >
              Add Question
            </Button>
        </Box> 
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth={true}>
        <DialogTitle>Add New Question</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="question"
            label="Question"
            type="text"
            fullWidth
            variant="outlined"
          />
          {/* Add more fields here as needed */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default QuestionAnswerForm;
