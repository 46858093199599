import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useContext, useEffect, useRef } from "react";
import { useState } from "react";
import LoungeProfileBio from "../LoungeProfileBio/LoungeProfileBio";
import LoungeItemContent from "../LoungeItemContent/LoungeItemContent";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { BigPlayButton, ControlBar, ForwardControl, Player } from "video-react";
import CloseIcon from "@mui/icons-material/Close";
import { allMembersUrl, baseUrl, createFeedsUrl } from "../../api/Api";
import axios from "axios";
import useGeolocation from "react-hook-geolocation";
import Dragger from "antd/es/upload/Dragger";
import { notifySuccess, notifyError } from "../../utils/Toast";
import { UserContext } from "../../utils/UserContext";

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";


// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { FeedLoadEffect } from "../PageLoadEffects";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

import Geocode from "react-geocode";
import { GoogleApiKey } from "../../utils/GoogleApiKey";
import { Box, DialogActions, DialogContentText, DialogTitle, FormControlLabel, Grid, Switch, TextareaAutosize } from "@mui/material";

// Uploader Modal Attached
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Avatar from "../../asset/image/avatar.jpg";
import LoungeCommentTwo from "../LoungeItemContent/LoungeCommentTwo";
import { useNavigate } from "react-router-dom";
import "video-react/dist/video-react.css";
import UploadLoader from "../PageLoadEffects/UploadLoader";
import LoungeUploadProgress from "../LoungeUploadProgress/LoungeUploadProgress";

import LoungeLatestJob from "../LoungeBody/LoungeLatestJob";
import LoungeLatestClassified from "../LoungeBody/LoungeLatestClassified";
import {
  useDeleteFeedMutation,
  useStoreFeedMutation,
} from "../../api/slices/loungeApiSlice";
import { Image } from "antd";

import LinkPreview from "@ashwamegh/react-link-preview";
import "@ashwamegh/react-link-preview/dist/index.css";

import { ShimmerPostItem } from "react-shimmer-effects";
import FeedPinnedForm from "./FeedPinnedForm";
import EmergencyRecordingIcon from '@mui/icons-material/EmergencyRecording';
import UploadIcon from '@mui/icons-material/Upload';

import Paper from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { ArrowBack } from "@material-ui/icons";
import CampaignIcon from '@mui/icons-material/Campaign';

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}


function CustomComponent({ loading, preview }) {
  return loading ? (
    <ShimmerPostItem card title cta />
  ) : (
    <div>
      <img height="100%" width="100%" src={preview.img} alt={preview.title} />
      <div className="previewFooter">
        <p className="domainName">{preview.domain}</p>
        <p className="title">{preview.title}</p>
        <p className="description">{preview.description}</p>
      </div>
    </div>
  );
}

const LoungeBody = ({ getPostsData, allFeeds,getPinnedPosts,getNotificationPosts, groupInfo, getDataFetching }) => {
  const navigate = useNavigate();
  const myRef = useRef(null);

  // console.log('dsds', myRef.current)

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose2 = () => {
    setOpen(false);
    setFileList([])
  };

  // Sticky right side
  Geocode.setApiKey(GoogleApiKey);
  const geolocation = useGeolocation();
  const [stickyNav, setStickyNav] = useState(false);
  const [visible, setVisible] = useState(false);
  const [postBtnVisible, setPostBtnVisible] = useState("btn btn-primary");
  const { userDetails, msDetails, loggedInUser } = useContext(UserContext);
  const [gender, setGender] = useState();
  const [avatar, setAvatar] = useState();
  const [name, setName] = useState();
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [cover, setCover] = useState();
  const [members, setAllMembers] = useState([]);
  const [tagValue, settagValue] = React.useState([]);
  const [modalShowExtraImage, setModalShowExtraImage] = React.useState(false);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const [totalMedia, setTotalMedia] = useState(null);
  // const [allFeeds, setAllFeeds] = useState(null);
  const [modalData, setModalData] = useState();
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [emojiShow, setEmojiShow] = useState(0);
  const maxNumber = 90;
  const [content, setContent] = useState("");
  const [location, setLocation] = useState("");
  const [professional, setProfessional] = useState(0);
  const [storePositionY, setStorePositionY] = useState(0);

  const [pinnedChecked, setPinnedChecked] = useState(false)
  const [announcementChecked, setAnnouncementChecked] = useState(false)

  const [bottom, setBottom] = useState(false);
  const [loadCount, setLoadCount] = useState(2);
  const token = localStorage.getItem("token");
  const [storeUuid, setStoreUuid] = useState(null);
  const getLoggedInUserInfo = localStorage.getItem("loggedInUserInfo");

  const [progressValue, setProgressValue] = useState(0);
  const [progressTotalLenght, setProgressTotalLength] = useState(0);

  const [storeFeed] = useStoreFeedMutation();
  const [deleteFeed] = useDeleteFeedMutation();

  const [storeAnnouncement, setStoreAnnouncement] = useState([])
  //getNotificationPosts
  useEffect(()=>{
    setStoreAnnouncement([])
    if(getNotificationPosts && getNotificationPosts !==undefined && getNotificationPosts?.length>0){
      setStoreAnnouncement((prevFileList) => [...prevFileList,  getNotificationPosts[0]]);
    }
  },[getNotificationPosts])


  // handle scroll pagination
  const handlePagination = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight;
    if (bottom) {
      setBottom(true);
    }
  };

  // hagination
  useEffect(() => {
    window.onscroll = () => {
      handlePagination();
      return () => (window.onscroll = null);
    };
  }, []);

  // scroll right side bar
  useEffect(() => {
    window.onscroll = () => {
      setStickyNav(window.scrollY >= 300 ? true : false);
      return () => (window.onscroll = null);
    };
  }, [stickyNav]);

  //get all members
  const membersUrl = `${allMembersUrl}/${msDetails.id}?current_page=1`;
  useEffect(() => {
    let config = {
      method: "get",
      url: membersUrl,
    };

    axios
      .request(config)
      .then((response) => {
        setAllMembers(response.data.data);
      })
      .catch((error) => {});
  }, []);

  //set User information
  useEffect(() => {
    if (userDetails != null) {
      setName(userDetails.profile.name);
      setUsername(userDetails.username);
      setEmail(userDetails.email);
      setGender(userDetails.profile.gender.name);
      // setAvatar(userDetails.profile.avatar);
      setCover(userDetails.profile.cover);

      if (getLoggedInUserInfo) {
        const parseInfo = JSON.parse(getLoggedInUserInfo);
        const parseUserDetails = JSON.parse(parseInfo?.user_details);
        if (parseUserDetails?.avater) {
          setAvatar(parseUserDetails?.avater);
        } else {
          setAvatar(userDetails.profile.avatar);
        }
      }
    }
  }, [getLoggedInUserInfo]);

  //Get all feeds
  // const getAllFeeds =() => {
  //   let config = {
  //     method: "get",
  //     url: `${createFeedsUrl}?microsite_id=${msDetails.id}`,
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   };
  //   axios
  //     .request(config)
  //     .then((response) => {
  //       setAllFeeds(response.data.data);
  //     })
  //     .catch((error) => { });
  // };
  // useEffect(() => {
  //   getAllFeeds();
  // }, []);
  const getLocation = () => {
    if (geolocation.error) {
      alert(geolocation.error.message);
    } else {
      Geocode.fromLatLng(geolocation.latitude, geolocation.longitude).then(
        (response) => {
          const address = response.results[9].formatted_address;
          setLocation(address);
        },
        (error) => {
          // console.error(error);
        }
      );
    }
  };

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };


  // handle pinned checked
  const handleChangePinned = (event) => {
    setPinnedChecked(event.target.checked);
  };
  // handle Annoucement
  const handleChangeAnnoucement = (event) => {
    setAnnouncementChecked(event.target.checked);
  };
  //  multiple files upload
  const handleChange = ({ fileList: newFileList }) => {
    var files = newFileList;
    var fileCount = 0;
    var videoCount = 0;

    for (var i = 0; i < files.length; i++) {
      if (
        files[i].type === "video/mp4" ||
        files[i].type === "video/mov" ||
        files[i].type === "video/avi" ||
        files[i].type === "video/mkv"
      ) {
        videoCount = videoCount + 1;
      }
      if (
        files[i].type === "application/pdf" ||
        files[i].type === "application/msword" ||
        files[i].type === "application/zip"
      ) {
        fileCount = fileCount + 1;
      }
      if (
        files[i].type === "image/png" ||
        files[i].type === "image/jpg" ||
        files[i].type === "image/jpeg" ||
        files[i].type === "image/gif"
      ) {
      }
    }

    if (videoCount > 1 || fileCount > 1) {
      notifyError("Limit exit (max: 1 video , max: 1 File)");
    } else {
      setFileList(newFileList);
    }
  };
  const uploadButton = (
    <div>
      <div
        style={{
          marginTop: 0,
          padding: "40px",
        }}
      >
        Upload Media (Photo,Video,Files)
      </div>
    </div>
  );

  const createPost = async (event) => {
    setEmojiShow(0);
    setPostBtnVisible("btn btn-primary d-none");
    setVisible(true);
    event.preventDefault();
    var files = fileList;

    const formData = new FormData();
    if (content !== "") {
      formData.append("content", content);
    }
    formData.append("microsite_id", msDetails?.id);
    formData.append("location", location);
    formData.append("professional", professional);
    formData.append("status", 1);

    if(pinnedChecked ===true){
      formData.append('pinned', 1)
    }else{
      formData.append('pinned', 0)
    }
    if(announcementChecked ===true){
      formData.append('notification', 1)
    }else{
      formData.append('notification', 0)
    }
    // formData.append("group_id", groupInfo.id);

    let allow = "1";
    for (var i = 0; i < files.length; i++) {
      if (
        files[i].type === "application/pdf" ||
        files[i].type === "application/msword" ||
        files[i].type === "image/png" ||
        files[i].type === "image/jpg" ||
        files[i].type === "image/jpeg" ||
        files[i].type === "image/gif"
      ) {
        formData.append("files[]", files[i].originFileObj);
      }
      if (
        files[i].type === "video/mp4" ||
        files[i].type === "video/webm"||
        files[i].type === "video/mov" ||
        files[i].type === "video/avi" ||
        files[i].type === "video/mkv"
      ) {
        formData.append("video", files[i].originFileObj);
      }
    }

    var url;
    if (storeUuid !== null) {
      url = `${createFeedsUrl}/${storeUuid}`;
    } else {
      url = createFeedsUrl;
    }

    const response = await storeFeed({
      token,
      formData,
      url,
      setProgressValue,
    });
    if (response?.data) {
      setProgressValue(0);
      setProgressTotalLength(0);
      setContent("");
      setTotalMedia(null);
      setFileList([]);
      setPinnedChecked(false)
      setAnnouncementChecked(false)

      setPostBtnVisible("btn btn-primary");
      setOpen(false);
      setVisible(false);
      if (storeUuid !== null) {
        setStoreUuid(null);
      }
    }
  };

  const setHidden = () => {
    if (document.body.style.overflow !== "hidden") {
      document.body.style.overflow = "initial";
    } else {
      document.body.style.overflow = "scroll";
    }
  };

  const [bgShow, setBgShow] = useState(0);
  const toggleBgShow = () => {
    if (bgShow === 0) {
      setBgShow(1);
    } else {
      setBgShow(0);
    }
  };
  const [bgShow2, setBgShow2] = useState(0);

  const [showMediaModal, setShowMediaModal] = useState("dialogContent-1");

  // hanndle load more button
  const handleLoadMore = () => {
    let config = {
      method: "get",
      url: `${createFeedsUrl}?microsite_id=${msDetails.id}&current_page=${loadCount}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios.request(config).then((response) => {
      setLoadCount(loadCount + 1);
      // setAllFeeds([...allFeeds,...response.data.data]);
    });
  };

  const handlePaste = (event) => {
    const items = event.clipboardData.items;

    var findText = false;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];

      if (item.type.includes("image") || item.type.includes("video")) {
        const blob = item.getAsFile();
        const file = new File(
          [blob],
          `pasted-${Date.now()}.${blob.type.split("/")[1]}`
        );
        const url = URL.createObjectURL(blob);

        const newFile = {
          uid: Date.now(),
          name: file.name,
          status: "done",
          url: url,
          type: blob.type,
          originFileObj: file,
        };

        setFileList((prevFileList) => [...prevFileList, newFile]);
      } else if (item.kind === "file") {
        const blob = item.getAsFile();
        const file = new File(
          [blob],
          `pasted-${Date.now()}.${blob.type.split("/")[1]}`
        );

        const newFile = {
          uid: Date.now(),
          name: file.name,
          status: "done",
          type: blob.type,
          originFileObj: file,
        };

        setFileList((prevFileList) => [...prevFileList, newFile]);
      } else if (item.type === "text/plain") {
        findText = true;
        item.getAsString((pastedText) => {
          setContent(pastedText);
        });
      }
    }
    if (findText === false) {
      toggleBgShow();
      setShowMediaModal("dialogContent-1 d-none");
    }
  };

  // set link preview
  const [previewUrl, setPreviewUrl] = useState(null);

  function findUrls(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.match(urlRegex) || [];
  }
  useEffect(() => {
    if (content) {
      const urls = findUrls(content);
      if (urls[0]) {
        setPreviewUrl(urls[0]);
      }
    }
  }, [content]);


  // for pinned post 
  const [openRecord, setOpenRecord] = useState(false);

  const handleClickOpenRecord = () => {
    setOpenRecord(true);
  };

  const handleCloseRecord = () => {
    setOpenRecord(false);
  };

  // console.log('getNotificationPosts', getNotificationPosts)


  // handle handlePastAnnoucement
  const handlePastAnnoucement = ()=>{
    setStoreAnnouncement(getNotificationPosts)
  }
  // handle handleClearPastAnnoucement
  const handleClearPastAnnoucement = ()=>{
    setStoreAnnouncement([getNotificationPosts[0]])
  }

  return (
    <Fragment>
      <div className="lounge">
        <Grid container spacing={2}>

          <Grid item xs={12} sm={12} md={12} lg={8}>
            

          {/* announcement header */}
          <Box display={'flex'} justifyContent={'space-between'}>
            {/* <Button variant="outlined" sx={{mb:1}}>+ Add</Button> */}
            {storeAnnouncement && storeAnnouncement?.length>0 &&  <small>Announcement</small>}
            {storeAnnouncement && storeAnnouncement?.length>=2 &&  <Button variant="outlined" sx={{mb:1,ml:1, fontSize:'12px !important',textTransform:'capitalize !important'}} onClick={(e)=> handleClearPastAnnoucement()}><ArrowBackIcon sx={{fontSize:'12px !important'}} /></Button>}
            {storeAnnouncement && storeAnnouncement?.length ===1 &&   <Button variant="outlined" sx={{mb:1,ml:1, fontSize:'12px !important', textTransform:'capitalize !important'}} onClick={(e)=> handlePastAnnoucement()}>Past Announcements</Button>}
          </Box>

          {/* announcement */}
            <div className="news-feed">
              {storeAnnouncement && storeAnnouncement?.length>0 &&
                storeAnnouncement.map((data, key) => {
                  {
                    /* No Image  No video only content */
                  }
                  if (
                    data.status === 1 &&
                    data.video === null &&
                    data.media.length === 0
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          {/* <div className="uploadede_content cursorPointer" onClick={(e)=> navigate('/loungeitemDetail', {state:{data:data}})}> */}
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                                notification={true}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    );
                  }
                  {
                    /* Single Image  View */
                  }
                  if (
                    data.status === 1 &&
                    data.video === null &&
                    data.media.length === 1
                  ) {
                    // console.log('data', data)
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                {/* <div className="image_container" onClick={(e)=>navigate('/loungeitemDetail', {state:{data:data,commentClick:false}})}> */}
                                <div className="uploaded_img_container">
                                  <div className="single_img">
                                    <Image
                                      onClick={(e) => {
                                        setHidden();
                                      }}
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                  {/* <div className="image_overly">
                                                  <img onClick={(e) => {
                                                      setHidden();
                                                    }} src={data.media[0].url}
                                                    alt={data.media[0].name}/>
                                                </div> */}
                                </div>
                              </Grid>
                            </Grid>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                                notification={true}
                              />
                            )}
                          </div>
                          {/* <LoungeComment/> */}
                        </div>
                      </>
                    );
                  }
                  {
                    /* 2 Image  View */
                  }

                  if (
                    data.status === 1 &&
                    data.video === null &&
                    data.media.length === 2
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={6}>
                                  {/* <div className="image_container" onClick={(e)=>navigate('/loungeitemDetail', {state:{data:data,commentClick:false}})}> */}
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[1].id}/${data.media[1].filename}`}
                                      alt={data.media[1].name}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                                notification={true}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    );
                  }
                  {
                    /* 3 Image  View */
                  }
                  if (
                    data.status === 1 &&
                    data.video === null &&
                    data.media.length === 3
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[1].id}/${data.media[1].filename}`}
                                      alt={data.media[1].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[2].id}/${data.media[2].filename}`}
                                      alt={data.media[2].name}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                                notification={true}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    );
                  }
                  {
                    /* 3+ Image  View */
                  }
                  if (
                    data.status === 1 &&
                    data.video === null &&
                    data.media.length > 3
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[1].id}/${data.media[1].filename}`}
                                      alt={data.media[1].name}
                                    />
                                  </div>
                                </Grid>

                                <Grid item xs={6}>
                                  <div className="image_container extra_img_relative">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[2].id}/${data.media[2].filename}`}
                                      alt={data.media[2].name}
                                    />
                                    <div
                                      className="extra_img_overly2"
                                      onClick={(e) => {
                                        setModalData(data.media);
                                        setModalShowExtraImage(true);
                                      }}
                                    >
                                      <span>{data.media.length - 3}+</span>
                                      {data?.media?.length > 3 &&
                                        data?.media.slice(3).map((image, i) => {
                                          return (
                                            <div className="extra_img_chields d-none">
                                              <Image
                                                src={`${baseUrl}/storage/media/${image.id}/${image.filename}`}
                                                alt={image.name}
                                              />
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                                notification={true}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    );
                  }
                  {
                    /* single video and single photo */
                  }
                  if (
                    data.status === 1 &&
                    data.video !== null &&
                    data.media.length === 1
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <div className="uploaded_Video_container">
                                    <Player src={`${baseUrl}/${data.video}`} muted={true}>
                                      <BigPlayButton position="center" />
                                      <ControlBar
                                        autoHide={false}
                                        className="my-class"
                                      >
                                        <ForwardControl
                                          seconds={10}
                                          order={3.2}
                                        />
                                      </ControlBar>
                                    </Player>
                                  </div>
                                </Grid>
                                <Grid item xs={12}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                                notification={true}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    );
                  }
                  {
                    /* single video and 2 image */
                  }
                  if (
                    data.status === 1 &&
                    data.video !== null &&
                    data.media.length === 2
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <div className="uploaded_Video_container">
                                    <Player src={`${baseUrl}/${data.video}`} muted={true}>
                                      <BigPlayButton position="center" />
                                      <ControlBar
                                        autoHide={false}
                                        className="my-class"
                                      >
                                        <ForwardControl
                                          seconds={10}
                                          order={3.2}
                                        />
                                      </ControlBar>
                                    </Player>
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[1].id}/${data.media[1].filename}`}
                                      alt={data.media[1].name}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                                notification={true}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    );
                  }
                  /* single video and 2+ image */
                  if (
                    data.status === 1 &&
                    data.video !== null &&
                    data.media.length > 2
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <div className="uploaded_Video_container">
                                    <Player src={`${baseUrl}/${data.video}`} muted={true}>
                                      <BigPlayButton position="center" />
                                      <ControlBar
                                        autoHide={false}
                                        className="my-class"
                                      >
                                        <ForwardControl
                                          seconds={10}
                                          order={3.2}
                                        />
                                      </ControlBar>
                                    </Player>
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container extra_img_relative">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[1].id}/${data.media[1].filename}`}
                                      alt={data.media[1].name}
                                    />
                                    <div
                                      className="extra_img_overly2"
                                      onClick={(e) => {
                                        setModalData(data.media);
                                        setModalShowExtraImage(true);
                                      }}
                                    >
                                      <span>{data.media.length - 2}+</span>
                                      {data?.media?.length > 2 &&
                                        data?.media.slice(2).map((image, i) => {
                                          return (
                                            <div className="extra_img_chields d-none">
                                              <Image
                                                src={`${baseUrl}/storage/media/${image.id}/${image.filename}`}
                                                alt={image.name}
                                              />
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                                notification={true}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    );
                  }
                  {
                    /* Single  Video View */
                  }
                  if (
                    data.status === 1 &&
                    data.video !== null &&
                    data.media.length === 0
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <div className="uploadede_item">
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <div className="uploaded_Video_container">
                                  <Player src={`${baseUrl}/${data.video}`} muted={true}>
                                    <BigPlayButton position="center" />
                                    <ControlBar
                                      autoHide={false}
                                      className="my-class"
                                    >
                                      <ForwardControl
                                        seconds={10}
                                        order={3.2}
                                      />
                                    </ControlBar>
                                  </Player>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                                notification={true}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    );
                  }
                })}
            </div>

            {/* pinned post */}
            <div className="news-feed">
              {getPinnedPosts &&
                getPinnedPosts.map((data, key) => {
                  {
                    /* No Image  No video only content */
                  }
                  if (
                    data.status === 1 &&
                    data.video === null &&
                    data.media.length === 0
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          {/* <div className="uploadede_content cursorPointer" onClick={(e)=> navigate('/loungeitemDetail', {state:{data:data}})}> */}
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}
                        </div>
                      </>
                    );
                  }
                  {
                    /* Single Image  View */
                  }
                  if (
                    data.status === 1 &&
                    data.video === null &&
                    data.media.length === 1
                  ) {
                    // console.log('data', data)
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                {/* <div className="image_container" onClick={(e)=>navigate('/loungeitemDetail', {state:{data:data,commentClick:false}})}> */}
                                <div className="uploaded_img_container">
                                  <div className="single_img">
                                    <Image
                                      onClick={(e) => {
                                        setHidden();
                                      }}
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                  {/* <div className="image_overly">
                                                  <img onClick={(e) => {
                                                      setHidden();
                                                    }} src={data.media[0].url}
                                                    alt={data.media[0].name}/>
                                                </div> */}
                                </div>
                              </Grid>
                            </Grid>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}

                          {/* <LoungeComment/> */}
                        </div>
                      </>
                    );
                  }
                  {
                    /* 2 Image  View */
                  }

                  if (
                    data.status === 1 &&
                    data.video === null &&
                    data.media.length === 2
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={6}>
                                  {/* <div className="image_container" onClick={(e)=>navigate('/loungeitemDetail', {state:{data:data,commentClick:false}})}> */}
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[1].id}/${data.media[1].filename}`}
                                      alt={data.media[1].name}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}
                        </div>
                      </>
                    );
                  }
                  {
                    /* 3 Image  View */
                  }
                  if (
                    data.status === 1 &&
                    data.video === null &&
                    data.media.length === 3
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[1].id}/${data.media[1].filename}`}
                                      alt={data.media[1].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[2].id}/${data.media[2].filename}`}
                                      alt={data.media[2].name}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}
                        </div>
                      </>
                    );
                  }
                  {
                    /* 3+ Image  View */
                  }
                  if (
                    data.status === 1 &&
                    data.video === null &&
                    data.media.length > 3
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[1].id}/${data.media[1].filename}`}
                                      alt={data.media[1].name}
                                    />
                                  </div>
                                </Grid>

                                <Grid item xs={6}>
                                  <div className="image_container extra_img_relative">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[2].id}/${data.media[2].filename}`}
                                      alt={data.media[2].name}
                                    />
                                    <div
                                      className="extra_img_overly2"
                                      onClick={(e) => {
                                        setModalData(data.media);
                                        setModalShowExtraImage(true);
                                      }}
                                    >
                                      <span>{data.media.length - 3}+</span>
                                      {data?.media?.length > 3 &&
                                        data?.media.slice(3).map((image, i) => {
                                          return (
                                            <div className="extra_img_chields d-none">
                                              <Image
                                                src={`${baseUrl}/storage/media/${image.id}/${image.filename}`}
                                                alt={image.name}
                                              />
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}
                        </div>
                      </>
                    );
                  }
                  {
                    /* single video and single photo */
                  }
                  if (
                    data.status === 1 &&
                    data.video !== null &&
                    data.media.length === 1
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <div className="uploaded_Video_container">
                                    <Player src={`${baseUrl}/${data.video}`} muted={true}>
                                      <BigPlayButton position="center" />
                                      <ControlBar
                                        autoHide={false}
                                        className="my-class"
                                      >
                                        <ForwardControl
                                          seconds={10}
                                          order={3.2}
                                        />
                                      </ControlBar>
                                    </Player>
                                  </div>
                                </Grid>
                                <Grid item xs={12}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}
                        </div>
                      </>
                    );
                  }
                  {
                    /* single video and 2 image */
                  }
                  if (
                    data.status === 1 &&
                    data.video !== null &&
                    data.media.length === 2
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <div className="uploaded_Video_container">
                                    <Player src={`${baseUrl}/${data.video}`} muted={true}>
                                      <BigPlayButton position="center" />
                                      <ControlBar
                                        autoHide={false}
                                        className="my-class"
                                      >
                                        <ForwardControl
                                          seconds={10}
                                          order={3.2}
                                        />
                                      </ControlBar>
                                    </Player>
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[1].id}/${data.media[1].filename}`}
                                      alt={data.media[1].name}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}
                        </div>
                      </>
                    );
                  }
                  /* single video and 2+ image */
                  if (
                    data.status === 1 &&
                    data.video !== null &&
                    data.media.length > 2
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <div className="uploaded_Video_container">
                                    <Player src={`${baseUrl}/${data.video}`} muted={true}>
                                      <BigPlayButton position="center" />
                                      <ControlBar
                                        autoHide={false}
                                        className="my-class"
                                      >
                                        <ForwardControl
                                          seconds={10}
                                          order={3.2}
                                        />
                                      </ControlBar>
                                    </Player>
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container extra_img_relative">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[1].id}/${data.media[1].filename}`}
                                      alt={data.media[1].name}
                                    />
                                    <div
                                      className="extra_img_overly2"
                                      onClick={(e) => {
                                        setModalData(data.media);
                                        setModalShowExtraImage(true);
                                      }}
                                    >
                                      <span>{data.media.length - 2}+</span>
                                      {data?.media?.length > 2 &&
                                        data?.media.slice(2).map((image, i) => {
                                          return (
                                            <div className="extra_img_chields d-none">
                                              <Image
                                                src={`${baseUrl}/storage/media/${image.id}/${image.filename}`}
                                                alt={image.name}
                                              />
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}
                        </div>
                      </>
                    );
                  }
                  {
                    /* Single  Video View */
                  }
                  if (
                    data.status === 1 &&
                    data.video !== null &&
                    data.media.length === 0
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item dotted_border"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <div className="uploadede_item">
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <div className="uploaded_Video_container">
                                  <Player src={`${baseUrl}/${data.video}`} muted={true}>
                                    <BigPlayButton position="center" />
                                    <ControlBar
                                      autoHide={false}
                                      className="my-class"
                                    >
                                      <ForwardControl
                                        seconds={10}
                                        order={3.2}
                                      />
                                    </ControlBar>
                                  </Player>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}
                        </div>
                      </>
                    );
                  }
                })}
            </div>

            {/* upload input form */}
            <div className="upload_box_container" onClick={handleClickOpen}>
              <div className="upload_box_top">
                <div className="uploader_profile">
                  {
                    //Avatar
                    avatar ? (
                      <div className="profile_img">
                        <img src={`${baseUrl}/${avatar}`} alt={name} />
                      </div>
                    ) : (
                      <div className="profile_img">
                        <img src={`${Avatar}`} alt={name} />
                      </div>
                    )
                  }
                </div>
                <div className="uploader_input">
                  <input
                    type="text"
                    className="form_control cursorPointer"
                    readOnly
                    placeholder="Post your text, video or photo"
                  />
                </div>
              </div>
            </div>
            {/* Uploader Container End */}

            {getDataFetching && <>{FeedLoadEffect()}</>}

            <div className="news-feed">
              {allFeeds &&
                allFeeds.map((data, key) => {
                  {
                    /* No Image  No video only content */
                  }
                  if (
                    data.status === 1 &&
                    data.video === null &&
                    data.media.length === 0
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          {/* <div className="uploadede_content cursorPointer" onClick={(e)=> navigate('/loungeitemDetail', {state:{data:data}})}> */}
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}
                        </div>
                      </>
                    );
                  }
                  {
                    /* Single Image  View */
                  }
                  if (
                    data.status === 1 &&
                    data.video === null &&
                    data.media.length === 1
                  ) {
                    // console.log('data', data)
                    return (
                      <>
                        <div
                          className="longue_item"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                {/* <div className="image_container" onClick={(e)=>navigate('/loungeitemDetail', {state:{data:data,commentClick:false}})}> */}
                                <div className="uploaded_img_container">
                                  <div className="single_img">
                                    <Image
                                      onClick={(e) => {
                                        setHidden();
                                      }}
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                  {/* <div className="image_overly">
                                                  <img onClick={(e) => {
                                                      setHidden();
                                                    }} src={data.media[0].url}
                                                    alt={data.media[0].name}/>
                                                </div> */}
                                </div>
                              </Grid>
                            </Grid>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}

                          {/* <LoungeComment/> */}
                        </div>
                      </>
                    );
                  }
                  {
                    /* 2 Image  View */
                  }

                  if (
                    data.status === 1 &&
                    data.video === null &&
                    data.media.length === 2
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={6}>
                                  {/* <div className="image_container" onClick={(e)=>navigate('/loungeitemDetail', {state:{data:data,commentClick:false}})}> */}
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[1].id}/${data.media[1].filename}`}
                                      alt={data.media[1].name}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}
                        </div>
                      </>
                    );
                  }
                  {
                    /* 3 Image  View */
                  }
                  if (
                    data.status === 1 &&
                    data.video === null &&
                    data.media.length === 3
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[1].id}/${data.media[1].filename}`}
                                      alt={data.media[1].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[2].id}/${data.media[2].filename}`}
                                      alt={data.media[2].name}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}
                        </div>
                      </>
                    );
                  }
                  {
                    /* 3+ Image  View */
                  }
                  if (
                    data.status === 1 &&
                    data.video === null &&
                    data.media.length > 3
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[1].id}/${data.media[1].filename}`}
                                      alt={data.media[1].name}
                                    />
                                  </div>
                                </Grid>

                                <Grid item xs={6}>
                                  <div className="image_container extra_img_relative">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[2].id}/${data.media[2].filename}`}
                                      alt={data.media[2].name}
                                    />
                                    <div
                                      className="extra_img_overly2"
                                      onClick={(e) => {
                                        setModalData(data.media);
                                        setModalShowExtraImage(true);
                                      }}
                                    >
                                      <span>{data.media.length - 3}+</span>
                                      {data?.media?.length > 3 &&
                                        data?.media.slice(3).map((image, i) => {
                                          return (
                                            <div className="extra_img_chields d-none">
                                              <Image
                                                src={`${baseUrl}/storage/media/${image.id}/${image.filename}`}
                                                alt={image.name}
                                              />
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}
                        </div>
                      </>
                    );
                  }
                  {
                    /* single video and single photo */
                  }
                  if (
                    data.status === 1 &&
                    data.video !== null &&
                    data.media.length === 1
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <div className="uploaded_Video_container">
                                    <Player src={`${baseUrl}/${data.video}`} muted={true}>
                                      <BigPlayButton position="center" />
                                      <ControlBar
                                        autoHide={false}
                                        className="my-class"
                                      >
                                        <ForwardControl
                                          seconds={10}
                                          order={3.2}
                                        />
                                      </ControlBar>
                                    </Player>
                                  </div>
                                </Grid>
                                <Grid item xs={12}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}
                        </div>
                      </>
                    );
                  }
                  {
                    /* single video and 2 image */
                  }
                  if (
                    data.status === 1 &&
                    data.video !== null &&
                    data.media.length === 2
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <div className="uploaded_Video_container">
                                    <Player src={`${baseUrl}/${data.video}`} muted={true}>
                                      <BigPlayButton position="center" />
                                      <ControlBar
                                        autoHide={false}
                                        className="my-class"
                                      >
                                        <ForwardControl
                                          seconds={10}
                                          order={3.2}
                                        />
                                      </ControlBar>
                                    </Player>
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[1].id}/${data.media[1].filename}`}
                                      alt={data.media[1].name}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}
                        </div>
                      </>
                    );
                  }
                  /* single video and 2+ image */
                  if (
                    data.status === 1 &&
                    data.video !== null &&
                    data.media.length > 2
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <Image.PreviewGroup
                            preview={{
                              onChange: (current, prev) =>
                                console.log(
                                  `current index: ${current}, prev index: ${prev}`
                                ),
                            }}
                          >
                            <div className="uploadede_item">
                              <Grid container spacing={1}>
                                <Grid item xs={12}>
                                  <div className="uploaded_Video_container">
                                    <Player src={`${baseUrl}/${data.video}`} muted={true}>
                                      <BigPlayButton position="center" />
                                      <ControlBar
                                        autoHide={false}
                                        className="my-class"
                                      >
                                        <ForwardControl
                                          seconds={10}
                                          order={3.2}
                                        />
                                      </ControlBar>
                                    </Player>
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[0].id}/${data.media[0].filename}`}
                                      alt={data.media[0].name}
                                    />
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <div className="image_container extra_img_relative">
                                    <Image
                                      src={`${baseUrl}/storage/media/${data.media[1].id}/${data.media[1].filename}`}
                                      alt={data.media[1].name}
                                    />
                                    <div
                                      className="extra_img_overly2"
                                      onClick={(e) => {
                                        setModalData(data.media);
                                        setModalShowExtraImage(true);
                                      }}
                                    >
                                      <span>{data.media.length - 2}+</span>
                                      {data?.media?.length > 2 &&
                                        data?.media.slice(2).map((image, i) => {
                                          return (
                                            <div className="extra_img_chields d-none">
                                              <Image
                                                src={`${baseUrl}/storage/media/${image.id}/${image.filename}`}
                                                alt={image.name}
                                              />
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Image.PreviewGroup>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}
                        </div>
                      </>
                    );
                  }
                  {
                    /* Single  Video View */
                  }
                  if (
                    data.status === 1 &&
                    data.video !== null &&
                    data.media.length === 0
                  ) {
                    return (
                      <>
                        <div
                          className="longue_item"
                          key={`${key}-${data.content}`}
                        >
                          <LoungeProfileBio
                            key={`${key}-${data.content}`}
                            getAllFeeds={""}
                            data={data}
                            comments={data?.comments}
                            modalOpen={handleClickOpen}
                            modalClose={handleClose2}
                            createPost={createPost}
                            setContent={setContent}
                            setStoreUuid={setStoreUuid}
                          />
                          <div className="uploadede_item">
                            <Grid container spacing={1}>
                              <Grid item xs={12}>
                                <div className="uploaded_Video_container">
                                  <Player src={`${baseUrl}/${data.video}`} muted={true}>
                                    <BigPlayButton position="center" />
                                    <ControlBar
                                      autoHide={false}
                                      className="my-class"
                                    >
                                      <ForwardControl
                                        seconds={10}
                                        order={3.2}
                                      />
                                    </ControlBar>
                                  </Player>
                                </div>
                              </Grid>
                            </Grid>
                          </div>
                          <div className="uploadede_content cursorPointer">
                            {data && (
                              <LoungeItemContent
                                data={data}
                                comments={data?.comments}
                                itemModalClick={(e) =>
                                  navigate("/loungeitemDetail", {
                                    state: { data: data, commentClick: true },
                                  })
                                }
                                getAllFeeds={""}
                              />
                            )}
                          </div>
                          {data && (
                            <LoungeCommentTwo
                              data={data}
                              comments={data?.comments}
                              itemModalClick={(e) =>
                                navigate("/loungeitemDetail", {
                                  state: { data: data, commentClick: true },
                                })
                              }
                            />
                          )}
                        </div>
                      </>
                    );
                  }
                })}
            </div>

            {/* for load more content       */}
            {bottom === true && allFeeds && allFeeds.length > 98 ? (
              <Box textAlign="center" sx={{ mt: 5 }}>
                <Button variant="contained">Load More...</Button>
              </Box>
            ) : (
              <>
                <Box textAlign="center" sx={{ mt: 5 }}>
                  <Button disabled onClick={(e) => handleLoadMore()}>
                    No More posts to see...
                  </Button>
                </Box>
              </>
            )}
          </Grid>

          {/* Another Grid */}
          <Grid item xs={12} sm={12} md={12} lg={4}>
            <div className="lounge_right_fixed">
              <div
                className={`lounge_right ${
                  stickyNav ? "lounge_right_sticky" : ""
                }`}
              >
                <LoungeLatestJob />
                <LoungeLatestClassified />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>

      {/* Uploader Modal */}
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose2}
        aria-labelledby="responsive-dialog-title"
        className="lounge_modal_mobile"
      >
        {/* Modal First View */}
        <div className={showMediaModal}>
          {storeUuid !== null ? (
            <Box sx={{ml:1, mt:1}} className="dialog_header">Update Your Post</Box>
          ) : (
            <div className="dialog_header dialog_header_content">
              <div> Create Your Post</div>
              <div className="closeBtn" onClick={handleClose2}>
                <CloseIcon />
              </div>
            </div>
          )}

          <DialogContent>
            <div
              className="upload_input_area"
              onClick={(e) => {
                emojiShow === 1 && setEmojiShow(0);
              }}
            >
              <TextareaAutosize
                autoFocus
                aria-label="minimum height"
                minRows={7}
                placeholder="Post your text, video or photo"
                className="Upload_textarea"
                onChange={(e) => setContent(e.target.value)}
                onPaste={handlePaste}
                value={content}
              />
              {location && (
                <p>
                  <FontAwesomeIcon icon={faLocationDot} /> {location}
                </p>
              )}

              {previewUrl !== null && (
                <LinkPreview
                  url={previewUrl}
                  customDomain="https://lpdg-server.azurewebsites.net/parse/link"
                  render={CustomComponent}
                />
              )}
            </div>
          </DialogContent>
          <div className="dialog_footer">
            <div className="emoji_picker">
              {emojiShow === 1 && (
                <Picker
                  style={{ height: "300px" }}
                  data={data}
                  comments={data?.comments}
                  previewPosition={"none"}
                  onEmojiSelect={(e) => {
                    setContent(content + " " + e.native);
                    setSelectedEmoji(e.native);
                  }}
                />
              )}
            </div>
            <div className="upload_attached">
              <ul>
                <li
                  onClick={() => {
                    toggleBgShow();
                    setShowMediaModal("dialogContent-1 d-none");
                  }}
                >
                  <span className="small-text">
                    Photo / Video
                  </span>
                  <Badge sx={{p:1.5}} badgeContent={fileList.length} color="primary">
                    <UploadIcon color="action" />
                  </Badge>
                </li>
                <li onClick={handleClickOpenRecord}>
                  <EmergencyRecordingIcon />
                </li>
                <li onClick={getLocation}>
                  <LocationOnIcon />
                  <span className="small-text">Location</span>
                </li>
                <li
                  onClick={(e) => {
                    emojiShow === 0 ? setEmojiShow(1) : setEmojiShow(0);
                  }}
                >
                  <span>🙂</span>
                </li>
                {/* {(loggedInUser?.user_type ==='admin' || userDetails.id === msDetails?.user_id) &&
                <li>
                   <FormControlLabel
                    sx={{margin: '0 !important', padding:'0 !important'}}
                    control={
                      <Switch 
                        size="small"
                        color="primary"
                        checked={pinnedChecked}
                        onChange={handleChangePinned}
                      />
                    }
                    labelPlacement="top"
                    label="📍"
                  />
                </li>} */}
                {(loggedInUser?.user_type ==='admin' || userDetails.id === msDetails?.user_id) &&
                <li>
                  <FormControlLabel
                    sx={{margin: '0 !important', padding:'0 !important'}}
                    control={
                      <Switch 
                        size="small"
                        color="primary"
                        checked={announcementChecked}
                        onChange={handleChangeAnnoucement}
                      />
                    }
                    labelPlacement="left"
                    label= {<CampaignIcon />}
                  />
                   
                </li>}
              </ul>
            </div>
           


            {visible === true && <UploadLoader />}


            <div className="uploader_btn_area">
              {(content && content.length > 0) || fileList.length > 0 ? (
                <Button
                  variant="contained"
                  onClick={(e) => createPost(e)}
                  className={postBtnVisible}
                >
                  {storeUuid !== null ? <>Update Now</> : <>Post now</>}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={(e) => createPost(e)}
                  className={`${postBtnVisible} disabled`}
                >
                  Post now
                </Button>
              )}
            </div>
          </div>
        </div>

        {/* Media Uploader Part  */}
        {bgShow === 1 && (
          <div className="dialogContent-2">
            <div className="dialog_header dialog_header_content">
              <div>Add Media</div>
              <div
                className="mediaBack_btn"
                onClick={() => {
                  toggleBgShow();
                  setShowMediaModal("dialogContent-1");
                }}
              >
                <ArrowBackIcon />
              </div>
            </div>
            <div className="mediaAdd_Body">
              <div className="mediaAdder">
                <Dragger
                  action="https://icircles.app/storage/logo/h9kMsnUQzKZ23PfgkLNhl1UxGWcjFXCSIntrNrD5.png"
                  name="file"
                  multiple={true}
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                >
                  {fileList.length >= 90 ? null : uploadButton}
                </Dragger>
              </div>
              {fileList && fileList.length > 0 && (
                <Box
                  sx={{ mb: 2 }}
                  display="flex"
                  justifyContent="center"
                  justifyItems="center"
                >
                  <Button
                    variant="outlined"
                    onClick={() => {
                      toggleBgShow();
                      setShowMediaModal("dialogContent-1");
                    }}
                  >
                    Next
                  </Button>
                </Box>
              )}
            </div>
          </div>
        )}

        {/* Tag Uploader Part */}
        {bgShow2 === 1 && (
          <div className="dialogContent-2">
            {/* <div className="dialog_header">
            <div className="mediaBack_btn"  onClick={() =>{toggleBgShow2(); setShowMediaModal('dialogContent-1')}}>
              <ArrowBackIcon/> 
            </div>
              Tag your friend
           </div> */}
            <DialogContent>
              <div className="TagBody">
                <Stack spacing={3}>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={members}
                    getOptionLabel={(option) => option.user.name}
                    defaultValue={tagValue}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        settagValue(newValue);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Add friends"
                        placeholder="Add..."
                      />
                    )}
                  />
                </Stack>
              </div>
            </DialogContent>
          </div>
        )}
      </Dialog>
      {/* Uploader Modal End */}


      {/* record video draggable modal */}
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={openRecord}
        onClose={handleCloseRecord}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
        sx={{overflow:'hidden !important'}}
      >
        <DialogTitle display={'flex'} justifyContent={'center'} alignContent={'center'} style={{ cursor: 'move' }} id="draggable-dialog-title">Record Video</DialogTitle>
        <DialogContent sx={{m:1.5,overflow:'hidden !important'}}>
            <FeedPinnedForm  setFileList={setFileList} handleCloseRecord={handleCloseRecord} />
        </DialogContent>
        <DialogActions >
          <Button autoFocus onClick={handleCloseRecord}>
            Close
          </Button>
        </DialogActions>
      </Dialog>


      {/* <ToastContainer limit={1} /> */}
      {progressValue > 0 && fileList.length > 0 && (
        <LoungeUploadProgress
          value={progressValue}
          progressTotalLenght={fileList.length}
        />
      )}
    </Fragment>
  );
};

export default LoungeBody;
