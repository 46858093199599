import React, { Fragment } from "react";
import { useState } from "react";
import {  reactionsUrl } from "../../api/Api";
import { useEffect } from "react";
import { useContext } from "react";
import { UserContext } from "../../utils/UserContext";
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useRemoveReactionMutation, useStoreReactionMutation } from "../../api/slices/loungeApiSlice";
import { Avatar, AvatarGroup, Box, Tooltip, Typography } from "@mui/material";

import likeImage from '../../asset/image/like.png'
import loveImage from '../../asset/image/heart.png'
import happyImage from '../../asset/image/happy.png'
import adoreImage from '../../asset/image/adore.png'
import laughImage from '../../asset/image/laugh.png'
import angryImage from '../../asset/image/angry.png'
import sadImage from '../../asset/image/sad.png'

import LinkPreview from '@ashwamegh/react-link-preview'
import '@ashwamegh/react-link-preview/dist/index.css'

import { ShimmerPostItem } from "react-shimmer-effects";

function CustomComponent({ loading, preview }) {
  return loading ? (
    <ShimmerPostItem card title cta />
  ) : (
    <div>
      <img height='100%' width='100%' src={preview.img} alt={preview.title} />
      {(preview.domain || preview.title || preview.description) && 
        <div className="previewFooter">
          <p className="domainName">{preview.domain}</p>
          <p className="title">{preview.title}</p>
          <p className="description">{preview.description}</p>
        </div>
      }
    </div>
  )
}

const LoungeItemContent = ({ data ,itemModalClick,getAllFeeds,getSingleFeedDetails, pinned, notification}) => {
  const navigate = useNavigate();
  const [paragraph, setParagraph] = useState("");
  const [myReaction,setMyReaction] = useState(null);
  const [meaning, setmeaning] = useState("");
  const [loaderVisible, setLoaderVisible] = useState(false)

  const url = window.location.href;
  const segName = url.split("/").pop();


  const token = localStorage.getItem("token");

  const [storeReaction] = useStoreReactionMutation()
  const [removeReaction] = useRemoveReactionMutation()

  const [storeLike, setStoreLike] = useState([])
  const [storeLove, setStoreLove] = useState([])
  const [storeHappy, setStoreHappy] = useState([])
  const [storeAdore, setStoreAdore] = useState([])
  const [storeLaugh, setStoreLaugh] = useState([])
  const [storeAngry, setStoreAngry] = useState([])
  const [storeSad, setStoreSad] = useState([])

  useEffect(() => {
    if (data && data.content && data.content !==null && data.content !=='' && data !== undefined) {
      setParagraph(data.content);
    }

    // store  actions
    var like = [];
    var love = [];
    var happy = [];
    var  adore = [];
    var laugh = [];
    var angry = [];
    var sad = [];
    if(data && data?.reactions && data.reactions.length>0){
      data.reactions.forEach(element => {
        if(element.reaction_type ==='like'){
          like.push(element)
        }
        if(element.reaction_type ==='love'){
          love.push(element)
        }
        if(element.reaction_type ==='happy'){
         happy.push(element)
        }
        if(element.reaction_type ==='adore'){
          adore.push(element)
        }
        if(element.reaction_type ==='laugh'){
          laugh.push(element)
        }
        if(element.reaction_type ==='angry'){
          angry.push(element)
        }
        if(element.reaction_type ==='sad'){
          sad.push(element)
        }
      });
      setStoreLike(like)
      setStoreLove(love)
      setStoreHappy(happy)
      setStoreAdore(adore)
      setStoreLaugh(laugh)
      setStoreAngry(angry)
      setStoreSad(sad)
    }
    
    if(data.my_reactions.length>0){
      if(data.my_reactions[0].reaction_type ==='like'){
        setMyReaction('👍')
      }
      if(data.my_reactions[0].reaction_type ==='love'){
        setMyReaction('❤️')
      }
      if(data.my_reactions[0].reaction_type ==='happy'){
        setMyReaction('😊')
      }
      if(data.my_reactions[0].reaction_type ==='adore'){
        setMyReaction('🤗')
      }
      if(data.my_reactions[0].reaction_type ==='laugh'){
        setMyReaction('😆')
      }
      if(data.my_reactions[0].reaction_type ==='angry'){
        setMyReaction('👿')
      }
      if(data.my_reactions[0].reaction_type ==='sad'){
        setMyReaction('😥')
      }
    }
  }, [data]);

  //emoji
  const reactionEmoji = {
    "👍": "like",
    "❤️": "love",
    "😊": "happy",
    "🤗": "adore",
    "😆": "laugh",
    "👿": "angry",
    "😥": "sad",
};
const [emojiClass, setEmojiClass] = useState(`emojiTab ${data.id} d-none`)
var feedsEmoji = Object.keys(reactionEmoji);


// emoji click handler
const emojiClickHandler =(emoji,postId,uuid)=> {
    setLoaderVisible(true)
    setEmojiClass(`emojiTab ${postId} d-none`)
    var meaning = reactionEmoji[emoji];
    setmeaning(meaning); 
    setMyReaction(emoji);

    let formData = new FormData();
    formData.append('post_id', postId);
    formData.append('reaction_type', meaning);

    var url;
    if(uuid !==null){
      url=`${reactionsUrl}/${uuid}`
    }else{
      url= `${reactionsUrl}`
    }
    storeReaction({token,formData,url})
    .unwrap()
    .then((response) => {
      if(getSingleFeedDetails !==undefined){
        getSingleFeedDetails();
      }
      setTimeout(()=>{
        setLoaderVisible(false)
      },2000)
    })
    .catch((error) => {
      setLoaderVisible(false)
    //  notifyError('Something went wrong')
    });

}

// handle remove reaction
const handleRemoveReaction=(uuid)=>{
  if(uuid !==null){
    var url = `${reactionsUrl}/${uuid}`;
    
    removeReaction({token,url})
    .then((response) => {
      setMyReaction(null)

      setStoreLike([])
      setStoreLove([])
      setStoreHappy([])
      setStoreAdore([])
      setStoreLaugh([])
      setStoreAngry([])
      setStoreSad([])
    })
    .catch((error) => {
      // notifyError('Something went wrong !')
    });
  }
}

// handle emoji class
const handleEmoji =(id,emojiClass)=>{
  if(emojiClass ===`emojiTab ${id} d-none`){
    setEmojiClass(`emojiTab ${id}`)
  }else{
    setEmojiClass(`emojiTab ${id} d-none`)
  }
}


// set link preview 
const [previewUrl, setPreviewUrl] = useState(null)

function findUrls(text) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.match(urlRegex) || [];
}
useEffect(()=>{
  if(paragraph){
    const urls = findUrls(paragraph);
    if(urls[0]){
      setPreviewUrl(urls[0])
    }
  }
},[paragraph])

// handle navigate details

const handleNavigateDetails = (data)=>{
  navigate('/loungeitemDetail', {state:{data:data}})
}

  return (
    <Fragment>
      <div className="LoungeItemContent">
        <div className="loungeContent"  >
          {segName !=='loungeitemDetail' && !pinned && data && data.content && data.content !=null && data.content !=='' && data !== undefined && 
          <>
          {previewUrl !==null 
          ?<><a href={paragraph} target="_blank">{paragraph}</a></>
          :<>{paragraph.length> 650 ? <Box onClick={(e)=> handleNavigateDetails(data)}>{paragraph.slice(0,650)}...<span  onClick={(e)=> navigate('/loungeitemDetail', {state:{data:data}})}>Read More</span> </Box>:<Box onClick={(e)=> handleNavigateDetails(data)}>{paragraph}</Box>}</>
          }
            {/* {paragraph.length> 650 ? <Box onClick={(e)=> handleNavigateDetails(data)}>{paragraph.slice(0,650)}...<span  onClick={(e)=> navigate('/loungeitemDetail', {state:{data:data}})}>Read More</span> </Box>:<Box onClick={(e)=> handleNavigateDetails(data)}>{paragraph}</Box>} */}
            <Box sx={{mt:2}}>
              {previewUrl !==null &&
                <a href={previewUrl} target="_blank" rel="noreferrer">
                  <LinkPreview
                      url={previewUrl}
                      customDomain='https://lpdg-server.azurewebsites.net/parse/link'
                      render={CustomComponent}
                    />
                    </a>
                }
            </Box>
          </>
          }
          {/* <ReactReadMoreReadLess
            charLimit={650}
            ellipsis={'...'}
            readLessStyle={{whiteSpace: "nowrap", textDecoration: "none"}}
            readMoreStyle={{whiteSpace: "nowrap", textDecoration: "none"}}
            readMoreText={"Read more"}
            readLessText={"Read less"}
            readMoreClassName="read-more-less--more"
            readLessClassName="read-more-less--less"
            onClick={(e)=> navigate('/loungeitemDetail', {state:{data:data}})}
          >
            {paragraph}
          </ReactReadMoreReadLess> */}

          {segName ==='loungeitemDetail' && !pinned && <><div className="content">{paragraph}</div></>}
         
        </div>
        {!notification && 
        <div className="loungeItem_footer">
          <div className="loung_item_status_update">
             <div className="status_update_left">

              {loaderVisible ===false ?  <div className="statusUpdate_item" >
                  <AvatarGroup max={7}>
                    {storeLike && storeLike.length>0 && 
                      <Tooltip arrow title={
                          <div>
                            {storeLike.map((data,i)=>{
                              return(
                                <Typography key={data.uuid} variant="body1" fontSize={12}>{data?.user?.name}</Typography>
                              )
                            })}
                            {/* Add more lines as needed */}
                          </div>
                        } >
                        <Avatar   sx={{ width: 20, height: 20 }} alt="Like" src={likeImage} />
                      </Tooltip>}
                    {storeLove && storeLove.length>0 && 
                      <Tooltip arrow title={
                          <div>
                            {storeLove.map((data,i)=>{
                              return(
                                <Typography key={data.uuid} variant="body1" fontSize={12}>{data?.user?.name}</Typography>
                              )
                            })}
                            {/* Add more lines as needed */}
                          </div>
                        } >
                        <Avatar   sx={{ width: 20, height: 20 }} alt="Like" src={loveImage} />
                      </Tooltip>}
                    {storeHappy && storeHappy.length>0 && 
                      <Tooltip arrow title={
                          <div>
                            {storeHappy.map((data,i)=>{
                              return(
                                <Typography key={data.uuid} variant="body1" fontSize={12}>{data?.user?.name}</Typography>
                              )
                            })}
                            {/* Add more lines as needed */}
                          </div>
                        } >
                        <Avatar   sx={{ width: 20, height: 20 }} alt="Like" src={happyImage} />
                      </Tooltip>}
                    {storeAdore && storeAdore.length>0 && 
                      <Tooltip arrow title={
                          <div>
                            {storeAdore.map((data,i)=>{
                              return(
                                <Typography key={data.uuid} variant="body1" fontSize={12}>{data?.user?.name}</Typography>
                              )
                            })}
                            {/* Add more lines as needed */}
                          </div>
                        } >
                        <Avatar   sx={{ width: 20, height: 20 }} alt="Like" src={adoreImage} />
                      </Tooltip>}
                    {storeLaugh && storeLaugh.length>0 && 
                      <Tooltip arrow title={
                          <div>
                            {storeLaugh.map((data,i)=>{
                              return(
                                <Typography key={data.uuid} variant="body1" fontSize={12}>{data?.user?.name}</Typography>
                              )
                            })}
                            {/* Add more lines as needed */}
                          </div>
                        } >
                        <Avatar   sx={{ width: 20, height: 20 }} alt="Like" src={laughImage} />
                      </Tooltip>}
                    {storeAngry && storeAngry.length>0 && 
                      <Tooltip arrow title={
                          <div>
                            {storeAngry.map((data,i)=>{
                              return(
                                <Typography key={data.uuid} variant="body1" fontSize={12}>{data?.user?.name}</Typography>
                              )
                            })}
                            {/* Add more lines as needed */}
                          </div>
                        } >
                        <Avatar   sx={{ width: 20, height: 20 }} alt="Like" src={angryImage} />
                      </Tooltip>}
                    {storeSad && storeSad.length>0 && 
                      <Tooltip arrow title={
                          <div>
                            {storeSad.map((data,i)=>{
                              return(
                                <Typography key={data.uuid} variant="body1" fontSize={12}>{data?.user?.name}</Typography>
                              )
                            })}
                            {/* Add more lines as needed */}
                          </div>
                        } >
                        <Avatar   sx={{ width: 20, height: 20 }} alt="Like" src={sadImage} />
                      </Tooltip>}
                  
                  </AvatarGroup>
                  {data.total_reactions >0 && <Box sx={{fontSize:'16px', ml:1}}>{data.total_reactions}</Box>}
                  
               </div>
               :
                 <div className="statusUpdate_item" >
                 <ThreeDots  height="30"  width="30"  radius="9" color="#4fa94d"  ariaLabel="three-dots-loading" visible={true} />
              </div>
               }
              
             {data.total_comments>0 && <div className="statusUpdate_item" >Comments
                  <Box sx={{fontSize:'16px', ml:1}}>
                      {data.total_comments}
                    </Box>
               </div>}
              
             </div>
             <div className="status_update_right">
             </div>
          </div>
         <div className="emojis_container">
            <div className={`${emojiClass} ${data.id}`}>
              {feedsEmoji.map(function (emoji) {
                    return (
                        <span
                            className="singleEmoji"
                            onClick={() => emojiClickHandler(emoji,data.id,(data.my_reactions.length>0)?data.my_reactions[0].uuid:null)}
                            key={emoji}>
                            {emoji}
                        </span>
                    );
              })}
        </div>
          </div>
        
          <div className="loungeItem_footer_status">
       
            <div className="footer_status_one" >

              {(myReaction !==null)?<span className="emoji_icon" onClick={(e)=> handleRemoveReaction((data.my_reactions.length>0)?data.my_reactions[0].uuid:null)}>{myReaction}
              </span>
              :<div onClick={(e)=>handleEmoji(data.id,emojiClass)}><ThumbUpIcon/><span>Like</span></div>}
            </div>
            <div className="footer_status_one" 
            onClick={(e)=> itemModalClick()}
            >
              <ChatBubbleIcon/>
              <span>Comment</span>
            </div>
           
          </div>
        </div>}
      </div>
    </Fragment>
  );
};

export default LoungeItemContent;