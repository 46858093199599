import React, { useCallback, useContext, useEffect, useState } from "react";
import { faCircleCheck, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import CloseIcon from "@mui/icons-material/Close";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import SendIcon from "@mui/icons-material/Send";
import CallIcon from "@mui/icons-material/Call";
import VideocamIcon from "@mui/icons-material/Videocam";
import { Avatar, Box, Grid, TextField, TextareaAutosize } from "@mui/material";
import { styled } from "@mui/system";
import { baseUrl, chatMessagesUrl, chatRoomUrl } from "../../api/Api";
import { UserContext } from "../../utils/UserContext";
import parser from "html-react-parser";

import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import axios from "axios";
import MainLoader from "../PageLoadEffects/MainLoader";

import GroupAddIcon from "@mui/icons-material/GroupAdd";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import Checkbox from "@mui/material/Checkbox";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";
import { notifyError } from "../../utils/Toast";
// import "";
import Echo from "laravel-echo";
import EditIcon from "@mui/icons-material/Edit";

import LinkPreview from "@ashwamegh/react-link-preview";
import "@ashwamegh/react-link-preview/dist/index.css";

import { ShimmerPostItem } from "react-shimmer-effects";
import CancelIcon from "@mui/icons-material/Cancel";

function CustomComponent({ loading, preview }) {
  return loading ? (
    <Box sx={{ width: "100%", height: "20%" }}>
      <ShimmerPostItem card title cta />
    </Box>
  ) : (
    <div>
      <img height="50%" width="50%" src={preview.img} alt={preview.title} />
      <div className="previewFooter">
        <p className="domainName">{preview.domain}</p>
        <p className="title">{preview.title}</p>
        <p className="description">{preview.description}</p>
      </div>
    </div>
  );
}
function CustomComponentDetails({ loading, preview }) {
  return loading ? (
    <Box sx={{ width: "100%", height: "100%" }}>
      <ShimmerPostItem card title cta />
    </Box>
  ) : (
    <div>
      <img height="100%" width="100%" src={preview.img} alt={preview.title} />
      <div className="previewFooter">
        <p className="domainName">{preview.domain}</p>
        <p className="title">{preview.title}</p>
        <p className="description">{preview.description}</p>
      </div>
    </div>
  );
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

// Style text area Part
const StyledTextarea = styled(TextareaAutosize)(
  ({ theme }) => `
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    padding: 10px 10px 10px 0px;
    border-radius: 12px;
    border:none;
    background-color:#f3f3f3;

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `
);

const ChatRoomDetailsBody = ({
  chatRoomDetails,
  singleRoom,
  handleChatDetails,
  getAllChatRooms,
  allMembers,
  userStatus,
  roomId,
  chatRooms,
  handleDeleteRoom,
  handleEditRoom,
}) => {
  const token = localStorage.getItem("token");
  const [isOnline, setIsOnline] = useState(true);
  const { userDetails, msDetails } = useContext(UserContext);
  const [emojiShow, setEmojiShow] = useState(0);
  const [selectedEmoji, setSelectedEmoji] = useState("");
  const [content, setContent] = useState("");
  const [media, setMedia] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);

  const [headerTime, setHeaderTime] = useState(null);

  const [groupName, setGroupName] = useState("");
  const [storeGroupMembers, setStoreGroupMembers] = useState([]);

  const [storeExistedMembers, setStoreExistedMembers] = useState([]);
  const [memberTobeAdd, setStoreMemberToBeAdd] = useState([]);
  const [ownerFind, setOwnerFind] = useState(false);

  const checkGroup = chatRoomDetails?.meta?.chat_room?.name.split("-Group In");
  const [holding, setHolding] = useState(false);

  const [previewUrl, setPreviewUrl] = useState(null);

  const getAllExistedMembers = useCallback(
    (chatRoomUuid) => {
      setOwnerFind(false);
      let config = {
        method: "get",
        url: `${chatRoomUrl}/${chatRoomUuid}/members?uuid=${chatRoomUuid}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          setStoreExistedMembers(response?.data);
          if (response?.data && response?.data.length > 0) {
            response?.data.forEach((member) => {
              if (
                member?.is_owner === true &&
                userDetails.uuid === member?.user.uuid
              ) {
                setOwnerFind(true);
              }
            });
          }

          var members = [];
          if (allMembers && allMembers.length > 0) {
            allMembers.forEach((element) => {
              members.push(element.user);
            });
          }
          // var difference = members.filter((x) => !response?.data.includes(x));

          var difference = members.filter(
            (item1) => !response.data.some((item2) => item2.name === item1.name)
          );

          var membertoAdd = [];
          if (difference && difference.length > 0) {
            difference.forEach((element) => {
              if (element?.user?.uuid !== userDetails.uuid) {
                membertoAdd.push(element);
              }
            });
          }
          setStoreMemberToBeAdd(membertoAdd);
        })
        .catch((error) => {
          setLoaderVisible(false);
        });
    },
    [allMembers, token, userDetails.uuid]
  );

  useEffect(() => {
    if (chatRoomDetails?.meta?.chat_room) {
      getAllExistedMembers(chatRoomDetails?.meta?.chat_room?.uuid);
    }
  }, [chatRoomDetails?.meta?.chat_room, getAllExistedMembers]);

  useEffect(() => {
    // Get the GMT offset
    const date = new Date();
    const gmtOffsetHours = date.getTimezoneOffset() / 60;
    const gmtOffsetMinutes = date.getTimezoneOffset() % 60;

    // Create a string representation of the GMT offset
    const gmtOffsetString =
      (gmtOffsetHours >= 0 ? "+" : "-") +
      ("0" + Math.abs(gmtOffsetHours)).slice(-2);

    setGMTOffset(gmtOffsetString);
  }, []);

  const echo = new Echo({
    broadcaster: "pusher",
    key: "69ef518953032858d64d",
    cluster: "ap1",
    encrypted: true,
    method: "post",
    authEndpoint: "https://icircles.app/api/broadcasting/auth", // Laravel Echo auth endpoint
    auth: {
      headers: {
        Authorization: `Bearer ${token}`, // Include the user's access token
      },
    },
  });

  const [membersActive, setMembersActive] = useState();
  useEffect(() => {
    setMembersActive();
    const channelName = `ChatRoom.${chatRoomDetails?.meta?.chat_room?.uuid}`;
    echo
      .join(channelName)
      .here((users) => {
        // console.log('Users currently in the channel:', users);
        if (users && users.length > 0) {
          users.forEach((element) => {
            if (element?.uuid !== userDetails.uuid) {
              setMembersActive(element);
            }
          });
        }
      })
      .joining((user) => {
        // console.log('User joining the channel:', user);
        setMembersActive(user);
      })
      .leaving((user) => {
        // console.log('User leaving the channel:', user);
        setMembersActive();
      })
      .listen("ChatMessageSentToRoom", (event) => {
        // console.log('Received ChatMessageSentToRoom event:', event);
        handleChatDetails(singleRoom.uuid);
        getAllChatRooms();
        // Your logic to handle the event
      });
  }, [chatRoomDetails?.meta?.chat_room?.uuid]);

  // console.log('activee', membersActive)

  // hnadle submit post
  const handleSubmitPost = () => {
    setHolding(true);
    setPreviewUrl(null);
    if (media !== null) {
      setIsLoading(true);
      setContent("sending...");
      let data = new FormData();

      data.append("chat_room", JSON.stringify(singleRoom));
      data.append("message", "hh");
      data.append("file", media);
      let config = {
        method: "post",
        url: chatMessagesUrl,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: data,
      };

      setMedia(null);

      axios
        .request(config)
        .then((response) => {
          getAllChatRooms();
          handleChatDetails(singleRoom.uuid);
          setContent("");
          setMedia(null);
          setIsLoading(false);
          setHolding(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setHolding(false);
        });
    }

    if (media === null) {
      setIsLoading(true);
      var data = JSON.stringify({
        message: content,
        chat_room: singleRoom,
      });
      setContent("sending...");

      let config = {
        method: "post",
        url: chatMessagesUrl,
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          getAllChatRooms();
          handleChatDetails(singleRoom.uuid);
          setContent("");
          setMedia(null);
          setIsLoading(false);
          setHolding(false);
        })
        .catch((error) => {
          setIsLoading(false);
          setHolding(false);
        });
    }
  };

  const [gmtOffset, setGMTOffset] = useState("");
  // set header time details
  useEffect(() => {
    const date = new Date();
    const gmtOffsetHours = -date.getTimezoneOffset() / 60; // Convert minutes to hours
    const gmtOffsetMinutes = -date.getTimezoneOffset() % 60;

    // Create a string representation of the GMT offset
    const gmtOffsetString =
      (gmtOffsetHours >= 0 ? "+" : "-") +
      ("0" + Math.abs(gmtOffsetHours)).slice(-2);

    setGMTOffset(gmtOffsetString);
    if (chatRoomDetails?.meta?.chat_room) {
      const newTime = new Date(chatRoomDetails?.meta?.chat_room?.updated_at);
      const updateTime = newTime.setHours(
        newTime.getHours() + parseInt(gmtOffsetString)
      );

      let diffTime = Math.abs(updateTime.valueOf() - new Date().valueOf());
      let days = diffTime / (24 * 60 * 60 * 1000);
      let hours = (days % 1) * 24;
      let minutes = (hours % 1) * 60;
      let secs = (minutes % 1) * 60;
      [days, hours, minutes, secs] = [
        Math.floor(days),
        Math.floor(hours),
        Math.floor(minutes),
        Math.floor(secs),
      ];
      var fullTime;

      if (secs !== 0) {
        fullTime = secs + "s";
      }
      if (minutes !== 0 && secs !== 0) {
        fullTime = minutes + "m" + " " + secs + "s";
      }
      if (hours !== 0 && minutes !== 0 && secs !== 0) {
        fullTime = hours + "h" + " " + minutes + "m" + " " + secs + "s";
      }
      if (days !== 0 && hours !== 0 && minutes !== 0 && secs !== 0) {
        fullTime =
          days +
          "d" +
          " " +
          hours +
          "h" +
          " " +
          minutes +
          "m" +
          " " +
          secs +
          "s";
      }
      setHeaderTime(fullTime);
    }
  }, []);

  // Chat Group
  const [chatGroup, setchatGroup] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClickOpenChatGroup = () => {
    setchatGroup(true);
  };

  const handleCloseChatGroup = () => {
    setchatGroup(false);
  };

  // handle create chat group
  const handleCreateGroup = () => {
    var data = JSON.stringify({
      // "is_public_group": 'false',
      members: storeGroupMembers,
      // "name": `${groupName} -Group In ${msDetails.name}`
    });
    let config = {
      method: "post",
      url: `${chatRoomUrl}/${chatRoomDetails?.meta?.chat_room.uuid}/members`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        handleChatDetails(response?.data?.uuid);
        getAllChatRooms();
        handleCloseChatGroup();
        getAllExistedMembers(chatRoomDetails?.meta?.chat_room.uuid);
        setGroupName("");
        setStoreGroupMembers([]);
        // setLoaderVisible(false);
      })
      .catch((error) => {
        // setLoaderVisible(false);
      });
  };

  // remove member from group
  const handleDeleteMember = (member) => {
    Swal.fire({
      heightAuto: false,
      backdrop: false,
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoaderVisible(true);
        var data = JSON.stringify({
          // "is_public_group": 'false',
          // members: [member?.user],
          members: member?.user.uuid,
          // "name": `${groupName} -Group In ${msDetails.name}`
        });
        let config = {
          method: "post",
          url: `${chatRoomUrl}/${chatRoomDetails?.meta?.chat_room.uuid}/removemembers`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: data,
        };
        axios
          .request(config)
          .then((response) => {
            // handleChatDetails(response?.data?.uuid);
            getAllChatRooms();
            handleCloseChatGroup();
            getAllExistedMembers(chatRoomDetails?.meta?.chat_room.uuid);
            setGroupName("");
            setStoreGroupMembers([]);
            setLoaderVisible(false);
          })
          .catch((error) => {
            notifyError("Something went wrong");
            setLoaderVisible(false);
          });
      }
    });
  };

  const handlePaste = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;

    // Check if there are files in the clipboard data
    if (clipboardData.items) {
      for (let i = 0; i < clipboardData.items.length; i++) {
        const item = clipboardData.items[i];

        // Handle pasted images
        if (item.type.indexOf("image") !== -1) {
          const blob = item.getAsFile();
          setMedia(blob);

          // const imageUrl = URL.createObjectURL(blob);
          // Use imageUrl as needed (e.g., display in an <img> element)
          // console.log('Pasted Image URL:', imageUrl);
        }

        // Handle pasted videos
        if (item.type.indexOf("video") !== -1) {
          const blob = item.getAsFile();
          setMedia(blob);

          // const videoUrl = URL.createObjectURL(blob);
          // Use videoUrl as needed (e.g., display in a <video> element)
          // console.log('Pasted Video URL:', videoUrl);
        }
      }
    }
  };

  // set link preview
  function findUrls(text) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.match(urlRegex) || [];
  }
  useEffect(() => {
    if (content) {
      const urls = findUrls(content);
      if (urls[0]) {
        setPreviewUrl(urls[0]);
      }
    }
  }, [content]);

  return (
    <>
      {loaderVisible === true && <MainLoader />}
      <div className="chatter_box_container">
        <div className="fixed_profilr_header">
          <div className="ct_left">
            <div className="profile">
              {chatRoomDetails?.meta?.chat_room?.member?.profile?.avatar ? (
                <img
                  src={`${baseUrl}/${chatRoomDetails?.meta?.chat_room?.member?.profile?.avatar}`}
                  alt={chatRoomDetails?.meta?.name}
                />
              ) : (
                <Avatar
                  alt={chatRoomDetails?.meta?.chat_room?.name}
                  src="/static/images/avatar/1.jpg"
                />
              )}
            </div>
            <div className="name_T">
              <div className="name">
                {chatRoomDetails?.meta?.chat_room?.name}
              </div>
              <div className="active_status">
                {membersActive?.name ? (
                  <span>Online</span>
                ) : (
                  <span>Offline</span>
                )}{" "}
                |
                {chatRoomDetails?.meta?.chat_room?.member_count
                  ? ` ${chatRoomDetails?.meta?.chat_room?.member_count} Members`
                  : headerTime !== null && headerTime}
              </div>
            </div>
          </div>
          <div className="ct_right">
            <div className="chat_title_dashed">
              {ownerFind === true && (
                <div
                  className="dashed_btn"
                  onClick={(e) =>
                    handleEditRoom(chatRoomDetails?.meta?.chat_room)
                  }
                >
                  <EditIcon />
                </div>
              )}
              {ownerFind === true && (
                <div
                  className="dashed_btn"
                  onClick={(e) =>
                    handleDeleteRoom(chatRoomDetails?.meta?.chat_room)
                  }
                >
                  <DeleteIcon />
                </div>
              )}

              {checkGroup && checkGroup.length === 2 && (
                <div className="dashed_btn" onClick={handleClickOpenChatGroup}>
                  <GroupAddIcon />
                </div>
              )}
              <div className="dashed_btn">
                <CallIcon />
              </div>
              <div className="dashed_btn">
                <VideocamIcon />
              </div>
            </div>
          </div>
        </div>
        <div className="chat_body">
          {isLoading ? (
            <div className="updated_div">
              <>
                {chatRoomDetails &&
                  chatRoomDetails?.data &&
                  chatRoomDetails.data.length > 0 &&
                  chatRoomDetails.data
                    .slice()
                    .reverse()
                    .map((data, i) => {
                      const newTime = new Date(data?.sent_at);
                      var todayTime;
                      var notTodaytime;

                      // Add the GMT offset to the time
                      const updateTime = newTime.setHours(
                        newTime.getHours() + parseInt(gmtOffset)
                      );

                      const splitedChatDate = new Date(updateTime)
                        .toDateString()
                        ?.split(" ");
                      const splitedChatTime = new Date(updateTime)
                        .toLocaleTimeString()
                        ?.split(" ");

                      if (
                        new Date(updateTime).toDateString() ===
                        new Date().toDateString()
                      ) {
                        var storeTime = splitedChatTime[0];
                        var splitedTime = storeTime?.split(":");
                        const time =
                          splitedTime[0] +
                          ":" +
                          splitedTime[1] +
                          " " +
                          splitedChatTime[1];
                        todayTime = time;
                      } else {
                        var storeTime = splitedChatTime[0];
                        var splitedTime = storeTime?.split(":");
                        const time =
                          splitedTime[0] +
                          ":" +
                          splitedTime[1] +
                          " " +
                          splitedChatTime[1];
                        notTodaytime =
                          splitedChatDate[1] +
                          " " +
                          splitedChatDate[2] +
                          "," +
                          splitedChatDate[3] +
                          "  " +
                          time;
                      }
                      // let diffTime = Math.abs(new Date().valueOf() - new Date(chat.created_at).valueOf());
                      let diffTime = Math.abs(
                        new Date(updateTime).valueOf() - new Date().valueOf()
                      );
                      let days = diffTime / (24 * 60 * 60 * 1000);
                      let hours = (days % 1) * 24;
                      let minutes = (hours % 1) * 60;
                      let secs = (minutes % 1) * 60;
                      [days, hours, minutes, secs] = [
                        Math.floor(days),
                        Math.floor(hours),
                        Math.floor(minutes),
                        Math.floor(secs),
                      ];
                      var fullTime;

                      if (secs !== 0) {
                        fullTime = secs + "s";
                      }
                      if (minutes !== 0 && secs !== 0) {
                        fullTime = minutes + "m" + " " + secs + "s";
                      }
                      if (hours !== 0 && minutes !== 0 && secs !== 0) {
                        fullTime =
                          hours + "h" + " " + minutes + "m" + " " + secs + "s";
                      }

                      var urls;

                      function findUrls(text) {
                        const urlRegex = /(https?:\/\/[^\s]+)/g;
                        return text.match(urlRegex) || [];
                      }

                      if (data?.message) {
                        const getUrls = findUrls(data?.message);
                        if (getUrls[0]) {
                          const finalUrl = getUrls[0].split('"');
                          if (finalUrl[0]) {
                            urls = finalUrl[0];
                          }
                        }
                      }

                      return (
                        <>
                          {/* Owner text and media */}
                          {data?.message !== "chat_room_create" &&
                            data?.user.uuid === userDetails.uuid && (
                              <>
                                {data?.message &&
                                  data?.message !== "%%chat_attachment_%%" && (
                                    <div className="own_massage_item">
                                      <div className="own_chat">
                                        <div className="senting_massage">
                                          {urls ? (
                                            <>
                                              <a
                                                href={urls}
                                                target="_blank"
                                                rel="noreferrer"
                                              >
                                                <LinkPreview
                                                  url={urls}
                                                  customDomain="https://lpdg-server.azurewebsites.net/parse/link"
                                                  render={
                                                    CustomComponentDetails
                                                  }
                                                />
                                              </a>
                                            </>
                                          ) : (
                                            <>{parser(data?.message)}</>
                                          )}

                                          <div className="chat_timing">
                                            <i>
                                              <FontAwesomeIcon
                                                icon={faCircleCheck}
                                              />
                                            </i>
                                            {new Date(
                                              updateTime
                                            ).toDateString() ===
                                            new Date().toDateString()
                                              ? todayTime
                                              : notTodaytime}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                {data?.attachments &&
                                  data?.attachments.length > 0 && (
                                    <div className="own_media_massage_item">
                                      {data.attachments.map((media, i) => {
                                        const getMediaType =
                                          media?.mime.split("/");
                                        if (getMediaType[0] === "image") {
                                          return (
                                            <>
                                              <div className="media_content">
                                                <img src={`${baseUrl}/storage/media/${media?.id}/${media?.filename}`} alt="" />
                                              </div>
                                              <div className="chat_timing">
                                                <i>
                                                  <FontAwesomeIcon
                                                    icon={faCircleCheck}
                                                  />
                                                </i>
                                                {new Date(
                                                  updateTime
                                                ).toDateString() ===
                                                new Date().toDateString()
                                                  ? todayTime
                                                  : notTodaytime}
                                              </div>
                                            </>
                                          );
                                        }
                                        if (media?.mime === "video/mp4") {
                                          return (
                                            <>
                                              <div className="media_content">
                                                <video
                                                  class="video_file_img"
                                                  controls
                                                  controlsList="nodownload"
                                                  preload="metadata"
                                                  webkit-playsinline="webkit-playsinline"
                                                >
                                                  <source
                                                    src={`${baseUrl}/storage/media/${media?.id}/${media?.filename}`}
                                                    type="video/mp4"
                                                    autostart="false"
                                                  />
                                                </video>
                                              </div>
                                              <div className="chat_timing">
                                                <i>
                                                  <FontAwesomeIcon
                                                    icon={faCircleCheck}
                                                  />
                                                </i>
                                                {new Date(
                                                  updateTime
                                                ).toDateString() ===
                                                new Date().toDateString()
                                                  ? todayTime
                                                  : notTodaytime}
                                              </div>
                                            </>
                                          );
                                        }
                                      })}
                                    </div>
                                  )}
                              </>
                            )}

                          {/* Chatter text and media */}
                          {data?.user.uuid !== userDetails.uuid && (
                            <div className="chat_massage_item">
                              <div className="profile_part">
                                <div className="chatter_profile">
                                  {chatRoomDetails?.meta?.chat_room?.member
                                    ?.profile?.avatar ? (
                                    <img
                                      src={`${baseUrl}/${chatRoomDetails?.meta?.chat_room?.member?.profile?.avatar}`}
                                      alt={chatRoomDetails?.meta?.name}
                                    />
                                  ) : (
                                    <Avatar
                                      alt={
                                        chatRoomDetails?.meta?.chat_room?.name
                                      }
                                      src="/static/images/avatar/1.jpg"
                                    />
                                  )}
                                </div>
                              </div>

                              {data?.message &&
                                data.message !== "%%chat_attachment_%%" && (
                                  <div className="chatter_chat">
                                    <div className="senting_massage">
                                      {parser(data?.message)}
                                      <div className="chat_timing">
                                        <i>
                                          <FontAwesomeIcon
                                            icon={faCircleCheck}
                                          />
                                        </i>
                                        {new Date(updateTime).toDateString() ===
                                        new Date().toDateString()
                                          ? todayTime
                                          : notTodaytime}
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {data?.attachments &&
                                data?.attachments.length > 0 && (
                                  <div className="chatter_media_massage_item">
                                    {data.attachments.map((media, i) => {
                                      if (media?.mime === "image/jpeg") {
                                        return (
                                          <>
                                            <div className="chatter_chat">
                                              <div className="media_content">
                                                <img src={`${baseUrl}/storage/media/${media?.id}/${media?.filename}`} alt="" />
                                              </div>

                                              <div className="chat_timing">
                                                <i>
                                                  <FontAwesomeIcon
                                                    icon={faCircleCheck}
                                                  />
                                                </i>
                                                {new Date(
                                                  updateTime
                                                ).toDateString() ===
                                                new Date().toDateString()
                                                  ? todayTime
                                                  : notTodaytime}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                      if (media?.mime === "video/mp4") {
                                        return (
                                          <>
                                            <div className="chatter_chat">
                                              <div className="media_content">
                                                <video
                                                  class="video_file_img"
                                                  controls
                                                  controlsList="nodownload"
                                                  preload="metadata"
                                                  webkit-playsinline="webkit-playsinline"
                                                >
                                                  <source
                                                    src={`${baseUrl}/storage/media/${media?.id}/${media?.filename}`}
                                                    type="video/mp4"
                                                    autostart="false"
                                                  />
                                                </video>
                                              </div>
                                              <div className="chat_timing">
                                                <i>
                                                  <FontAwesomeIcon
                                                    icon={faCircleCheck}
                                                  />
                                                </i>
                                                {new Date(
                                                  updateTime
                                                ).toDateString() ===
                                                new Date().toDateString()
                                                  ? todayTime
                                                  : notTodaytime}
                                              </div>
                                            </div>
                                          </>
                                        );
                                      }
                                    })}
                                  </div>
                                )}
                            </div>
                          )}
                        </>
                      );
                    })}
              </>
              <div className="own_massage_item">
                <div className="own_chat">
                  <div className="senting_massage">
                    {content}
                    <div className="chat_timing">
                      <FontAwesomeIcon icon={faClock} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              {chatRoomDetails &&
                chatRoomDetails?.data &&
                chatRoomDetails.data.length > 0 &&
                chatRoomDetails.data.map((data, i) => {
                  const newTime = new Date(data?.sent_at);

                  var todayTime;
                  var notTodaytime;

                  // Add the GMT offset to the time
                  const updateTime = newTime.setHours(
                    newTime.getHours() + parseInt(gmtOffset)
                  );

                  const splitedChatDate = new Date(updateTime)
                    .toDateString()
                    ?.split(" ");
                  const splitedChatTime = new Date(updateTime)
                    .toLocaleTimeString()
                    ?.split(" ");

                  if (
                    new Date(updateTime).toDateString() ===
                    new Date().toDateString()
                  ) {
                    var storeTime = splitedChatTime[0];
                    var splitedTime = storeTime?.split(":");
                    const time =
                      splitedTime[0] +
                      ":" +
                      splitedTime[1] +
                      " " +
                      splitedChatTime[1];
                    todayTime = time;
                  } else {
                    var storeTime = splitedChatTime[0];
                    var splitedTime = storeTime?.split(":");
                    const time =
                      splitedTime[0] +
                      ":" +
                      splitedTime[1] +
                      " " +
                      splitedChatTime[1];
                    notTodaytime =
                      splitedChatDate[1] +
                      " " +
                      splitedChatDate[2] +
                      "," +
                      splitedChatDate[3] +
                      " " +
                      time;
                  }

                  // let diffTime = Math.abs(new Date().valueOf() - new Date(chat.created_at).valueOf());
                  let diffTime = Math.abs(
                    new Date(updateTime).valueOf() - new Date().valueOf()
                  );
                  let days = diffTime / (24 * 60 * 60 * 1000);
                  let hours = (days % 1) * 24;
                  let minutes = (hours % 1) * 60;
                  let secs = (minutes % 1) * 60;
                  [days, hours, minutes, secs] = [
                    Math.floor(days),
                    Math.floor(hours),
                    Math.floor(minutes),
                    Math.floor(secs),
                  ];
                  var fullTime;

                  if (secs !== 0) {
                    fullTime = secs + "s";
                  }
                  if (minutes !== 0 && secs !== 0) {
                    fullTime = minutes + "m" + " " + secs + "s";
                  }
                  if (hours !== 0 && minutes !== 0 && secs !== 0) {
                    fullTime =
                      hours + "h" + " " + minutes + "m" + " " + secs + "s";
                  }

                  var urls;

                  function findUrls(text) {
                    const urlRegex = /(https?:\/\/[^\s]+)/g;
                    return text.match(urlRegex) || [];
                  }

                  if (data?.message) {
                    const getUrls = findUrls(data?.message);
                    if (getUrls[0]) {
                      const finalUrl = getUrls[0].split('"');
                      if (finalUrl[0]) {
                        urls = finalUrl[0];
                      }
                    }
                  }

                  return (
                    <>
                      {/* Owner text and media */}
                      {data?.message !== "chat_room_create" &&
                        data?.user.uuid === userDetails.uuid && (
                          <>
                            {data?.message &&
                              data?.message !== "%%chat_attachment_%%" && (
                                <div className="own_massage_item">
                                  <div className="own_chat">
                                    <div className="senting_massage">
                                      {urls ? (
                                        <>
                                          <a
                                            href={urls}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <LinkPreview
                                              url={urls}
                                              customDomain="https://lpdg-server.azurewebsites.net/parse/link"
                                              render={CustomComponentDetails}
                                            />
                                          </a>
                                        </>
                                      ) : (
                                        <>{parser(data?.message)}</>
                                      )}
                                      <div className="chat_timing">
                                        <i>
                                          <FontAwesomeIcon
                                            icon={faCircleCheck}
                                          />
                                        </i>
                                        {new Date(updateTime).toDateString() ===
                                        new Date().toDateString()
                                          ? todayTime
                                          : notTodaytime}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {data?.attachments &&
                              data?.attachments.length > 0 && (
                                <div className="own_media_massage_item">
                                  {data.attachments.map((media, i) => {
                                    const getMediaType = media?.mime.split("/");

                                    if (getMediaType[0] === "image") {
                                      return (
                                        <>
                                          <div className="media_content">
                                            <img src={`${baseUrl}/storage/media/${media?.id}/${media?.filename}`} alt="" />
                                          </div>
                                          <div className="chat_timing">
                                            <i>
                                              <FontAwesomeIcon
                                                icon={faCircleCheck}
                                              />
                                            </i>
                                            {new Date(
                                              updateTime
                                            ).toDateString() ===
                                            new Date().toDateString()
                                              ? todayTime
                                              : notTodaytime}
                                          </div>
                                        </>
                                      );
                                    }
                                    if (media?.mime === "video/mp4") {
                                      return (
                                        <>
                                          <div className="media_content">
                                            <video
                                              class="video_file_img"
                                              controls
                                              controlsList="nodownload"
                                              preload="metadata"
                                              webkit-playsinline="webkit-playsinline"
                                            >
                                              <source
                                                src={`${baseUrl}/storage/media/${media.id}/${media?.filename}`}
                                                type="video/mp4"
                                                autostart="false"
                                              />
                                            </video>
                                          </div>
                                          <div className="chat_timing">
                                            <i>
                                              <FontAwesomeIcon
                                                icon={faCircleCheck}
                                              />
                                            </i>
                                            {new Date(
                                              updateTime
                                            ).toDateString() ===
                                            new Date().toDateString()
                                              ? todayTime
                                              : notTodaytime}
                                          </div>
                                        </>
                                      );
                                    }
                                  })}
                                </div>
                              )}
                          </>
                        )}

                      {/* Chatter text and media */}
                      {data?.user.uuid !== userDetails.uuid && (
                        <div className="chat_massage_item">
                          <div className="profile_part">
                            <div className="chatter_profile">
                              {chatRoomDetails?.meta?.chat_room?.member?.profile
                                ?.avatar ? (
                                <img
                                  src={`${baseUrl}/${chatRoomDetails?.meta?.chat_room?.member?.profile?.avatar}`}
                                  alt={chatRoomDetails?.meta?.name}
                                />
                              ) : (
                                <Avatar
                                  alt={chatRoomDetails?.meta?.chat_room?.name}
                                  src="/static/images/avatar/1.jpg"
                                />
                              )}
                            </div>
                          </div>

                          {data?.message &&
                            data.message !== "%%chat_attachment_%%" && (
                              <div className="chatter_chat">
                                <div className="senting_massage">
                                  {parser(data?.message)}
                                  <div className="chat_timing">
                                    <i>
                                      <FontAwesomeIcon icon={faCircleCheck} />
                                    </i>
                                    {new Date(updateTime).toDateString() ===
                                    new Date().toDateString()
                                      ? todayTime
                                      : notTodaytime}
                                  </div>
                                </div>
                              </div>
                            )}

                          {data?.attachments &&
                            data?.attachments.length > 0 && (
                              <div className="chatter_media_massage_item">
                                {data.attachments.map((media, i) => {
                                  const getMediaType = media?.mime.split("/");
                                  if (getMediaType[0] === "image") {
                                    return (
                                      <>
                                        <div className="chatter_chat">
                                          <div className="media_content">
                                            <img src={`${baseUrl}/storage/media/${media?.id}/${media?.filename}`} alt={media?.filename} />
                                          </div>

                                          <div className="chat_timing">
                                            <i>
                                              <FontAwesomeIcon
                                                icon={faCircleCheck}
                                              />
                                            </i>
                                            {new Date(
                                              updateTime
                                            ).toDateString() ===
                                            new Date().toDateString()
                                              ? todayTime
                                              : notTodaytime}
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }
                                  if (media?.mime === "video/mp4") {
                                    return (
                                      <>
                                        <div className="chatter_chat">
                                          <div className="media_content">
                                            <video
                                              class="video_file_img"
                                              controls
                                              controlsList="nodownload"
                                              preload="metadata"
                                              webkit-playsinline="webkit-playsinline"
                                            >
                                              <source
                                                src={`${baseUrl}/storage/media/${media?.id}/${media?.filename}`}
                                                type="video/mp4"
                                                autostart="false"
                                              />
                                            </video>
                                          </div>
                                          <div className="chat_timing">
                                            <i>
                                              <FontAwesomeIcon
                                                icon={faCircleCheck}
                                              />
                                            </i>
                                            {new Date(
                                              updateTime
                                            ).toDateString() ===
                                            new Date().toDateString()
                                              ? todayTime
                                              : notTodaytime}
                                          </div>
                                        </div>
                                      </>
                                    );
                                  }
                                })}
                              </div>
                            )}
                        </div>
                      )}
                    </>
                  );
                })}
            </>
          )}
        </div>
        <div className="chatting_Input_container">
          <div className="attachment_content">
            {/* Photo Part */}
            {media && media !== null && media.type !== "video/mp4" && (
              <div className="photo_content">
                <div className="photo_item">
                  <img src={URL.createObjectURL(media)} alt="" />
                  <div className="photo_overly" onClick={(e) => setMedia(null)}>
                    <CloseIcon />
                  </div>
                </div>
              </div>
            )}

            {/* Vedio Part */}
            {media && media !== null && media.type === "video/mp4" && (
              <div className="vedio_content">
                <div className="vedio_item">
                  <video
                    class="video_file_img"
                    controlsList="nodownload"
                    preload="metadata"
                    webkit-playsinline="webkit-playsinline"
                  >
                    <source src={URL.createObjectURL(media)} type="video/mp4" />
                  </video>
                  <div className="vedio_overly" onClick={(e) => setMedia(null)}>
                    <CloseIcon />
                  </div>
                  <i className="play_icon">
                    <PlayCircleOutlineIcon />
                  </i>
                </div>
              </div>
            )}

            {/* link preview part */}
            {previewUrl !== null && (
              <>
                <LinkPreview
                  url={previewUrl}
                  customDomain="https://lpdg-server.azurewebsites.net/parse/link"
                  render={CustomComponent}
                />
                <CancelIcon onClick={(e) => setPreviewUrl(null)}></CancelIcon>
              </>
            )}
          </div>
          <div className="chating_path">
            <div className="path_side_1">
              <div className={"file-input"}>
                <input
                  disabled={content !== "" ? true : false}
                  type="file"
                  name="file-input"
                  id="file-input"
                  class={"file-input__input"}
                  onChange={(e) => setMedia(e.target.files[0])}
                />
                <label class="file-input__label" for="file-input">
                  <i className={content !== "" ? "cursor_denied" : ""}>
                    <AddToPhotosIcon />
                  </i>
                </label>
              </div>
              <i
                onClick={(e) => {
                  emojiShow === 0 ? setEmojiShow(1) : setEmojiShow(0);
                }}
              >
                <EmojiEmotionsIcon />
              </i>
              <div className="chat_emojy_picker">
                {emojiShow === 1 && (
                  <Picker
                    style={{ height: "300px" }}
                    data={data}
                    previewPosition={"none"}
                    onEmojiSelect={(e) => {
                      setContent(content + " " + e.native);
                      setSelectedEmoji(e.native);
                    }}
                  />
                )}
              </div>
            </div>
            <StyledTextarea
              aria-label="empty textarea"
              placeholder="Type ... "
              value={content}
              className={
                media && media !== null
                  ? "cursor_denied path_side_2 "
                  : "path_side_2"
              }
              // value={content}
              onChange={(e) => setContent(e.target.value)}
              onPaste={handlePaste}
              disabled={
                holding === true || (media && media !== null) ? true : false
              }
            />
            {holding ? (
              true && (
                <>
                  <div className={"path_side_3 cursor_denied_3"}>
                    <i className={holding === true ? "cursor_denied_3" : ""}>
                      <SendIcon />
                    </i>
                  </div>
                </>
              )
            ) : (
              <>
                <div
                  className={"path_side_3"}
                  onClick={(e) =>
                    (media && media !== null) || content !== ""
                      ? handleSubmitPost()
                      : ""
                  }
                >
                  <i
                    className={
                      (media && media !== null) || content !== ""
                        ? "send_icon"
                        : ""
                    }
                  >
                    <SendIcon />
                  </i>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {/* Chat Group Create Modal */}
      <Dialog
        fullScreen={fullScreen}
        open={chatGroup}
        onClose={handleCloseChatGroup}
        aria-labelledby="responsive-dialog-title"
        className="chat_group_modal"
      >
        <DialogTitle sx={{ mt: 4 }} id="responsive-dialog-title">
          {`Member ${chatRoomDetails?.meta?.chat_room?.name}`}
        </DialogTitle>
        <DialogContent>
          <div className="chat_group_body">
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <div className="search_user">
                  <Autocomplete
                    sx={{ mt: 2 }}
                    multiple
                    id="checkboxes-tags-demo"
                    // value={memberTobeAdd}
                    onChange={(event, newValue) => {
                      setStoreGroupMembers(newValue);
                    }}
                    options={memberTobeAdd}
                    disableCloseOnSelect
                    getOptionLabel={(option) => option?.name}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Add Member"
                        placeholder="Search"
                      />
                    )}
                  />
                </div>
              </Grid>
              <Grid item lg={12} md={12} sm={12} sx={12}>
                <div className="list_title">Member List </div>
                {storeExistedMembers &&
                  storeExistedMembers.length > 0 &&
                  storeExistedMembers.map((member, i) => {
                    return (
                      <div className="Aded_list" key={i}>
                        <div className="list_item">
                          <div className="item_left">
                            <Avatar
                              alt={member?.name}
                              src={
                                member?.user?.profile?.avatar
                                  ? `${baseUrl}/${member?.user?.profile?.avatar}`
                                  : ""
                              }
                            />
                            <div className="u_name">{member?.name}</div>
                          </div>
                          {ownerFind === true &&
                            userDetails.uuid !== member?.user.uuid && (
                              <div className="item_right">
                                <i onClick={(e) => handleDeleteMember(member)}>
                                  <DeleteIcon />
                                </i>
                              </div>
                            )}
                        </div>
                      </div>
                    );
                  })}
              </Grid>
            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseChatGroup}>
            Cancel
          </Button>
          <Button variant="contained" onClick={(e) => handleCreateGroup()}>
            Add Now
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChatRoomDetailsBody;
