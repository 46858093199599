import React, { Fragment, useEffect, useState } from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import HomePage from "../pages/HomePage";
import MemberPage from "../pages/MemberPages/MemberPage";
import ClassifiedPage from "../pages/ClassifiedPages/ClassifiedPage";
import JobPage from "../pages/JobPages/JobPage";
import NotificationPage from "../pages/NotificationPages/NotificationPage";
import MyJobListPage from "../pages/JobPages/MyJobListPage";
import JobItemDetailsPage from "../pages/JobPages/JobItemDetailsPage";
import NoticePage from "../pages/NoticePages/NoticePage";
import LoginPage from "../pages/AuthenticationPages/LoginPage";
import LoungeItemDetailPage from "../pages/LoungePages/LoungeItemDetailPage";
import PostPage from "../pages/UserPages/ProfilePage";
import PostPageOther from "../pages/UserPages/ProfilePageOther";
import RegisterPage from "../pages/AuthenticationPages/RegisterPage";
import CreatCommuinityFormPage from "../pages/AuthenticationPages/CreatCommuinityFormPage";
import Protected from "../utils/Protected";
import NotFoundPage from "../pages/404Pages/NotFoundPage";
import { UserContext } from "../utils/UserContext";
import TopNaviation from "../components/TopNavigation/TopNaviation";
import LeftSideBar from "../components/LeftSideBar/LeftSideBar";
// import TestPage from "../pages/TestPage";
import NotFoundComponent from "../components/404/404";
import AddMemberPage from "../pages/MemberPages/AddMemberPage";
import axios from "axios";
import {
  commonUserUrl,
  magazineMainUrl,
  micrositeDetailsUrl,
  userInfoUrl,
  userMicrositesUrl,
} from "../api/Api";
import MyJobCreatePage from "../pages/JobPages/MyJobCreatePage";
import { Container } from "@mui/material";
import ClassifiedCreatePage from "../pages/ClassifiedPages/ClassifiedCreatePage";
import ClassifiedItemDetailPage from "../pages/ClassifiedPages/ClassifiedItemDetailPage";
import MyClassifiedPage from "../pages/ClassifiedPages/MyClassifiedPage";
import ClassifiedOrderListPage from "../pages/ClassifiedPages/ClassifiedOrderListPage";
import SingleClassifiedOrderPage from "../pages/ClassifiedPages/SingleClassifiedOrderPage";
import GeneralLoginPage from "../pages/AuthenticationPages/GeneralLoginPage";
import UserCommunitiesSitesPage from "../pages/UserPages/UserCommunitiesSitesPage";
import ClassifiedOrderDetailPage from "../pages/ClassifiedPages/ClassifiedOrderDetailPage";
import CreatCommuinityEmailCheckPage from "../pages/AuthenticationPages/CreatCommuinityEmailCheckPage";
import CreatCommuinityLoginPage from "../pages/AuthenticationPages/CreatCommuinityLoginPage";
import CreatCommuinityRegisterPage from "../pages/AuthenticationPages/CreatCommuinityRegisterPage";
import ResourcePage from "../pages/ResourcePages/ResourcePage";
import ResourceDetailsPage from "../pages/ResourcePages/ResourceDetailsPage";
import ResourceCreatePage from "../pages/ResourcePages/ResourceCreatePage";
import MyResourcePage from "../pages/ResourcePages/MyResourcePage";
import LocalBusinessPage from "../pages/LocalBusinessPages/LocalBusinessPage";
import LocalBusinessDetailsPage from "../pages/LocalBusinessPages/LocalBusinessDetailsPage";
import LocalBusinessCreatePage from "../pages/LocalBusinessPages/LocalBusinessCreatePage";
import myLocalBusinessPage from "../pages/LocalBusinessPages/MyLocalBusinessPage";
import EventPage from "../pages/EventPages/EventPage";
import EventCreatePage from "../pages/EventPages/EventCreatePage";
import EventDetailsPage from "../pages/EventPages/EventDetailsPage";
import MediaPage from "../pages/Media/MediaPage";
import MediaNestedPage from "../pages/Media/MediaNestedPage";
import EventInvitedPeoplePage from "../pages/EventPages/EventInvitedPeoplePage";
import CommunityPackegs from "../pages/AuthenticationPages/CommunityPackegs";
import ChatPage from "../pages/ChatPage";
import CommunitySetUpPage from "../pages/CommunitySetUp/CommunitySetUpPage";
import CommunityPtofileUpdatePage from "../pages/CommunitySetUp/CommunityPtofileUpdatePage";
import IcirclesLoginPage from "../pages/AuthenticationPages/IcirclesLoginPage";
import CommunityInfoPage from "../pages/CommunityInfoPage";
import MemberJoinPage from "../pages/MemberJoinPage/MemberJoinPage";
import InvitatonPage from "../pages/AuthenticationPages/InvitatonPage";
import chat from "../asset/image/Chat.png";
import InvitatonMemberPage from "../pages/AuthenticationPages/InvitatonMemberPage";
import DeveloperReportPage from "../pages/BugReports/DeveloperReportPage";
import AdminReportPage from "../pages/BugReports/AdminReportPage";
import AdminReportCreatePage from "../pages/BugReports/AdminReportCreatePage";
import AdminReportDetailsPage from "../pages/BugReports/AdminReportDetailsPage";
import EventBannrUpdatePage from "../pages/EventPages/EventBannrUpdatePage";
import ActivateAccountPage from "../pages/AuthenticationPages/ActivateAccountPage";
import CommunityInfoPublicPage from "../pages/CommunityInfoPublicPage";
import CommunityPtofileCompletePage from "../pages/CommunitySetUp/CommunityPtofileCompletePage";
import RegisterUserSearchPage from "../pages/AuthenticationPages/RegisterUserSearchPage";
import { notifyError } from "../utils/Toast";
import CompleteBannerAvatarPage from "../pages/CommunitySetUp/CompleteBannerAvatarPage";
import MatrimonialPage from "../pages/Matrimonial/MatrimonialPage";
import MagazinePage from "../pages/Magazine/MagazinePage";
import MagazineMenuPage from "../pages/Magazine/MagazineMenuPage";
import MagazineMenuCreatePage from "../pages/Magazine/MagazineMenuCreatePage";
import MagazineContentPage from "../pages/Magazine/MagazineContentPage";
import MagazineContentCreatePage from "../pages/Magazine/MagazineContentCreatePage";
import MagazineDetailsPage from "../pages/Magazine/MagazineDetailsPage";
import MagazineDemoPage from "../pages/Magazine/MagazineDemoPage";
import RepresentativePage from "../pages/RepresentativePage/RepresentativePage";
import RepresentativeDetailsPage from "../pages/RepresentativePage/RepresentativeDetailsPage";
import RepresentativeCreatePage from "../pages/RepresentativePage/RepresentativeCreatePage";
import MyRepresentativePage from "../pages/RepresentativePage/MyRepresentativePage";
import MagazineDetailsDemoPage from "../pages/Magazine/MagazineDetailsDemoPage";
import MagazineCatgoryPostsPage from "../pages/Magazine/MagazineCatgoryPostsPage";
import MagazineCategoryDetailsPage from "../pages/Magazine/MagazineCategoryDetailsPage";
import MagazineSeeAllPostsPage from "../pages/Magazine/MagazineSeeAllPostsPage";
import MatrimonyProfilePage from "../pages/Matrimonial/MatrimonyProfilePage";
import MatrimonyProfileCreatePage from "../pages/Matrimonial/MatrimonyProfileCreatePage";
import MatrimonialCardDetails from "../pages/Matrimonial/MatrimonialCardDetails";
import MatrimonialPreviewThemePage from "../pages/Matrimonial/Theme/Preview/PreviewThemePage";

import MyMagazineContentPage from "../pages/Magazine/MyMagazineContentPage";
import ServiceRequestPage from "../pages/LocalBusinessPages/ServiceRequestPage";
import ServiceRequestDetailsPage from "../pages/LocalBusinessPages/ServiceRequestDetailsPage";
import IcirclesResetPassowrdPage from "../pages/AuthenticationPages/IcirclesResetPassowrdPage";
import MatrimonialThemeSelectPage from "../pages/Matrimonial/MatrimonialThemeSelectPage";
import Echo from "laravel-echo";
import PurchaseThemePage from "../pages/Matrimonial/Theme/PurchaseThemePage";
import PaymentPage from "../pages/Payment/StripePayment/PaymentPage";
import PayStationPaymentPage from "../pages/Payment/PaayStation/PayStationPaymentPage";
import MatrimonialMyThemePage from "../pages/Matrimonial/MatrimonialMyThemePage";
import DynamicThemePage from "../pages/Matrimonial/Theme/Dynamic/DynamicThemePage";
import Pusher from "pusher-js";
import LocalBusinessSubscriptionPage from "../pages/LocalBusinessPages/LocalBusinessSubscriptionPage";
import LocalBusinessPaymentPage from "../pages/LocalBusinessPages/LocalBusinessPaymentPage";
import GroupMainPage from "../pages/Groups/GroupMainPage";
import GroupCreatePage from "../pages/Groups/GroupCreatePage";
import GroupLoungePage from "../pages/Groups/GroupLoungePage";
import GroupMyPage from "../pages/Groups/GroupMyPage";
import BroadcastHome from "../pages/VideoBroadcast/BroadcastHome";
import BroadcastDetails from "../pages/VideoBroadcast/BroadcastDetails";
import LookBack from "../pages/VideoBroadcast/LookBack";
import BroadcastLibrary from "../pages/VideoBroadcast/BroadcastLibrary";
import BroadcastUploadForm from "../pages/VideoBroadcast/BroadcastUploadForm";
import CategoryPage from "../pages/VideoBroadcast/CategoryPage";
import CategoryCreatePage from "../pages/VideoBroadcast/CategoryCreatePage";
import SingleCategory from "../pages/VideoBroadcast/SingleCategory";
import TermsAndServicePage from "../pages/TermsAndServicePage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import BroadcastAdmin from "../pages/VideoBroadcast/BroadcastAdmin";
import BroadcastUploadEditForm from "../pages/VideoBroadcast/BroadcastUploadEditForm";
import EventFlipBookPage from "../pages/EventPages/EventFlipBookPage";
import BroadcastPollLists from "../pages/VideoBroadcast/BroadcastPollLists";
import PollHome from "../pages/Poll/PollHome";
import PollCreateFormPage from "../pages/Poll/PollCreateFormPage";
import PollAdmin from "../pages/Poll/PollAdmin";
import PollLibrary from "../pages/Poll/PollLibrary";
import PollEditFormPage from "../pages/Poll/PollEditFormPage";
import AddressPage from "../pages/AddressPage/AddressPage";
import AddressCreatePage from "../pages/AddressPage/AddressCreatePage";
import AddressBulkUploadPage from "../pages/AddressPage/AddressBulkUploadPage";
import PollDetailsPage from "../pages/Poll/PollDetailsPage";
import PollResultReviewPage from "../pages/Poll/PollResultReviewPage";
import PollRewardClaimCenterPage from "../pages/Poll/PollRewardClaimCenterPage";
import PollRewardWalletPage from "../pages/Poll/PollRewardWalletPage";
import CommunityDetails from "../pages/AuthenticationPages/CommunityDetails";

// import Payment from "../pages/StripePayment/Payment";

const AppRouter = () => {
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem("data"));
  const msDetails = JSON.parse(localStorage.getItem("msDetails"));
  const loggedInUser = JSON.parse(localStorage.getItem("loggedInUserInfo"));
  const magazine = JSON.parse(localStorage.getItem("magazine"));
  const url = window.location.href;
  const getUrl = window.location.href;
  const segNamae = getUrl.split("/").pop();
  const segName2 = getUrl.split("/");

  let params = new URLSearchParams(url);
  var token = params.get("t");
  var uuid = params.get("u");
  // function for microsite details
  const getMicrositeDetails = (getUuid, getToken) => {
    let config = {
      method: "get",
      url: `${micrositeDetailsUrl}/${getUuid}`,
    };

    axios.request(config).then((response) => {
      localStorage.setItem("msDetails", JSON.stringify(response.data));
      if (getToken && getUuid) {
        localStorage.setItem("token", getToken);
        getUserDetails(getToken, getUuid);
      }
      if (getUuid && getToken === "") {
        window.location.href = "/login";
      }
    });
  };

  // function for user details
  const getUserDetails = (getToken, getUuid) => {
    let config = {
      method: "get",
      url: userInfoUrl,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios.request(config).then((response) => {
      localStorage.setItem("token", getToken);
      localStorage.setItem("data", JSON.stringify(response.data));
      if (getToken && getToken) {
        window.location.href = "/";
      }
      if (getUuid === "" && segName2[3].match("createCommuinity") === null) {
        window.location.href = "/commuinityList";
      }
      if (getUuid === "" && segName2[3].match("createCommuinity") !== null) {
        window.location.href = "/create";
      }
    });
  };

  // getUserDetails('1135|D5MPogmB0fr41T36qhlPmAKRxVdwQNd5cdxowlDm')
  //uuid and token finds
  if (token !== null && uuid !== null) {
    getUserDetails(token, uuid);
    getMicrositeDetails(uuid, token);
  }
  //only uuid find
  if (uuid !== null && (token === "" || token === null)) {
    getMicrositeDetails(uuid, "");
  }
  //only token
  if (token !== null && (uuid === "" || uuid === null)) {
    getUserDetails(token, "");
  }

  useEffect(() => {
    if (msDetails && userDetails && segNamae === "") {
      // console.log("hit from app router");
      let config = {
        method: "get",
        url: `${commonUserUrl}/${userDetails?.id}/${msDetails?.id}`,
      };
      axios.request(config).then((res) => {
        if (res?.data && res?.data?.status === 2) {
          notifyError("You ware invited please accept invitation first");
          setTimeout(() => {
            navigate("/community-info-public", {
              state: { uuid: msDetails.uuid, memberStatus: 2, reload: true },
            });
          }, 2000);
        }
        if (res?.data && res?.data?.status === 3) {
          notifyError("You are blocked on this community");
          setTimeout(() => {
            navigate("/community-info-public", {
              state: { uuid: msDetails.uuid, memberStatus: 3, reload: true },
            });
          }, 2000);
        }
        if (res?.data && res?.data?.status === 0) {
          notifyError("Your joining status is pending...");
          setTimeout(() => {
            navigate("/community-info-public", {
              state: { uuid: msDetails.uuid, memberStatus: 0, reload: true },
            });
          }, 2000);
        }
      });
      // .catch((e) => {
      //   notifyError("Your are not member of this comminity join first.");
      //   setTimeout(() => {
      //     navigate("/community-info-public", {
      //       state: { uuid: msDetails.uuid, memberStatus: -1, reload: true },
      //     });
      //   }, 1500);
      // });
    }
  }, []);

  const getAllUserMicrosites = async () => {
    var ids = [];
    let config = {
      method: "get",
      url: `${userMicrositesUrl}?type_id=9`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    await axios
      .request(config)
      .then((response) => {
        if (response?.data?.data.length > 0) {
          response?.data?.data.forEach((element) => {
            if (element.status === 1) {
              ids.push(element.microsites.id);
            }
          });
        }
        localStorage.setItem("user-ms-ids", JSON.stringify(ids));
      })
      .catch((e) => {
        localStorage.setItem("user-ms-ids", JSON.stringify(ids));
      });
  };

  useEffect(() => {
    if (token) {
      getAllUserMicrosites();
    }
  }, []);

  // handle get main magazine datas
  const handleGetMainMagazine = () => {
    let config = {
      method: "get",
      url: `${magazineMainUrl}/${msDetails.id}`,
    };

    axios
      .request(config)
      .then((response) => {
        localStorage.setItem("magazine", JSON.stringify(response.data));
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  // useEffect(()=>{
  //   if(magazine ===null && msDetails?.id){
  //     handleGetMainMagazine()
  //   }else if(magazine?.microsite_info?.id !==msDetails?.id){
  //     handleGetMainMagazine()
  //   }
  // },[magazine])
  useEffect(() => {
    if (msDetails?.id) {
      handleGetMainMagazine();
    }
  }, []);

  const getUserInfo = localStorage?.getItem("loggedInUserInfo");
  const storeUserDetailsParse = JSON?.parse(getUserInfo);
  const [visit, setVisit] = useState(0);
  useEffect(() => {
    if (visit === 0) {
      if (
        storeUserDetailsParse?.status === 1 &&
        storeUserDetailsParse?.user_details
      ) {
        const parseData = JSON.parse(storeUserDetailsParse?.user_details);
        if (!parseData?.name) {
          // window.location.href='/complete-profile'
          navigate("/complete-profile");
          setVisit(1);
        }
      }
    }
  }, [
    visit,
    navigate,
    storeUserDetailsParse?.status,
    storeUserDetailsParse?.user_details,
  ]);

  const getToken = localStorage.getItem("token");
  const echo = new Echo({
    broadcaster: "pusher",
    key: "69ef518953032858d64d",
    cluster: "ap1",
    encrypted: true,
    method: "post",
    authEndpoint: "https://icircles.app/api/broadcasting/auth", // Laravel Echo auth endpoint
    auth: {
      headers: {
        Authorization: `Bearer ${getToken}`, // Include the user's access token
      },
    },
  });

  return (
    <Fragment>
      <UserContext.Provider
        value={{ userDetails, msDetails, loggedInUser, magazine }}
      >
        {userDetails &&
        msDetails &&
        segNamae !== "commuinityList" &&
        segNamae !== "login" &&
        segNamae !== "register" &&
        segNamae !== "home" &&
        segNamae !== "community-info-public" &&
        segNamae !== "register-user-search" &&
        segNamae !== "privacy-policy" &&
        segNamae !== "terms-of-service" &&
        segNamae !== "community-packeges" ? (
          <TopNaviation />
        ) : (
          ""
        )}

        <Container>
          {userDetails &&
          msDetails &&
          segNamae !== "commuinityList" &&
          segNamae !== "login" &&
          segNamae !== "register" &&
          segNamae !== "home" &&
          segNamae !== "community-info-public" &&
          segNamae !== "complete-profile" &&
          segNamae !== "register-user-search" &&
          segNamae !== "newCommuinity" &&
          segNamae !== "banner" &&
          segNamae !== "avatar" &&
          segNamae !== "privacy-policy" &&
          segNamae !== "terms-of-service" &&
          segNamae !== "community-packeges" ? (
            <LeftSideBar />
          ) : (
            ""
          )}

          {userDetails &&
          msDetails &&
          segNamae !== "commuinityList" &&
          segNamae !== "login" &&
          segNamae !== "register" &&
          segNamae !== "home" &&
          segNamae !== "community-info-public" &&
          segNamae !== "register-user-search" &&
          segNamae !== "chat" &&
          segNamae !== "community-packeges" ? (
            <>
              {/* <div className="chat_box_icon">
                <Link to="/chat">
                  <div className="chat_button">
                    <img src={chat} alt="" />
                  </div>
                </Link>
              </div> */}
            </>
          ) : (
            ""
          )}

          <Routes>
            {/* Authentication Pages Routes */}
            <Route
              exact
              path="/"
              element={<Protected Component={HomePage} />}
            />
            <Route exact path="/login" element={<LoginPage />} />
            <Route exact path="/terms-of-service" element={<TermsAndServicePage />} />
            <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route
              exact
              path="/login-icircles"
              element={<IcirclesLoginPage />}
            />

            <Route
              exact
              path="/password-reset"
              element={<IcirclesResetPassowrdPage />}
            />

            <Route
              exact
              path="/activate-account"
              element={<ActivateAccountPage />}
            />
            <Route
              exact
              path="/create"
              element={<CreatCommuinityEmailCheckPage />}
            />
            <Route exact path="/register" element={<RegisterPage />} />
            <Route
              exact
              path="/register-user-search"
              element={<RegisterUserSearchPage />}
            />
            <Route exact path="/invitation" element={<InvitatonPage />} />
            <Route
              exact
              path="/member-invitation"
              element={<InvitatonMemberPage />}
            />
            <Route
              exact
              path="/create/login"
              element={<CreatCommuinityLoginPage />}
            />
            <Route
              exact
              path="/create/newCommuinity"
              element={<CreatCommuinityFormPage />}
            />
            <Route
              exact
              path="/create/register"
              element={<CreatCommuinityRegisterPage />}
            />
            <Route exact path="/home" element={<GeneralLoginPage />} />
            <Route exact path="/home/:uuid" element={<CommunityDetails />} />
            <Route
              exact
              path="commuinityList"
              element={<UserCommunitiesSitesPage />}
            />
            <Route
              exact
              path="community-packeges"
              element={<CommunityPackegs />}
            />

            <Route exact path="/member-join" element={<MemberJoinPage />} />

            <Route
              exact
              path="/community-info"
              element={<Protected Component={CommunityInfoPage} />}
            />
            <Route
              exact
              path="/community-info-public"
              element={<CommunityInfoPublicPage />}
            />

            {/* Reports Pages Routes */}
            <Route
              exact
              path="/report-developer"
              element={<Protected Component={DeveloperReportPage} />}
            />
            <Route
              exact
              path="/report"
              element={<Protected Component={AdminReportPage} />}
            />
            <Route
              exact
              path="/report-user"
              element={<Protected Component={AdminReportPage} />}
            />
            <Route
              exact
              path="/report/details"
              element={<Protected Component={AdminReportDetailsPage} />}
            />
            <Route
              exact
              path="/report/form"
              element={<Protected Component={AdminReportCreatePage} />}
            />

            {/* Job Pages Routes */}
            <Route
              exact
              path="/job"
              element={<Protected Component={JobPage} />}
            />
            <Route
              exact
              path="/my-job-list"
              element={<Protected Component={MyJobListPage} />}
            />
            <Route
              exact
              path="/my-job"
              element={<Protected Component={MyJobCreatePage} />}
            />
            <Route
              exact
              path="/job-details"
              element={<Protected Component={JobItemDetailsPage} />}
            />

            {/* Classified Pages Routes */}
            <Route
              exact
              path="/classified-create"
              element={<Protected Component={ClassifiedCreatePage} />}
            />
            <Route
              exact
              path="/classified-detail"
              element={<Protected Component={ClassifiedItemDetailPage} />}
            />
            <Route
              exact
              path="/classified"
              element={<Protected Component={ClassifiedPage} />}
            />
            <Route
              exact
              path="myClassified"
              element={<Protected Component={MyClassifiedPage} />}
            />
            <Route
              exact
              path="/singleOrder"
              element={<Protected Component={SingleClassifiedOrderPage} />}
            />
            <Route
              exact
              path="/orderList"
              element={<Protected Component={ClassifiedOrderListPage} />}
            />
            <Route
              exact
              path="/orderDetails"
              element={<Protected Component={ClassifiedOrderDetailPage} />}
            />

            {/* Lounge Pages Routes */}
            <Route
              exact
              path="/loungeitemDetail"
              element={<Protected Component={LoungeItemDetailPage} />}
            />
            <Route
              exact
              path="/community-profile"
              element={<Protected Component={PostPage} />}
            />
            <Route
              exact
              path="/community-profile/update"
              element={<Protected Component={CommunityPtofileUpdatePage} />}
            />
            <Route
              exact
              path="/complete-profile"
              element={<Protected Component={CommunityPtofileCompletePage} />}
            />
            <Route
              exact
              path="/complete-profile/:name"
              element={<Protected Component={CompleteBannerAvatarPage} />}
            />
            <Route
              exact
              path="/community-profile/other"
              element={<Protected Component={PostPageOther} />}
            />

            {/* Member Pages Routes */}
            <Route
              exact
              path="/member"
              element={<Protected Component={MemberPage} />}
            />
            <Route
              exact
              path="/add-member"
              element={<Protected Component={AddMemberPage} />}
            />

            {/* Resource Pages Routes */}
            <Route
              exact
              path="/resource"
              element={<Protected Component={ResourcePage} />}
            />
            <Route
              exact
              path="/resource-details"
              element={<Protected Component={ResourceDetailsPage} />}
            />
            <Route
              exact
              path="/resource-create"
              element={<Protected Component={ResourceCreatePage} />}
            />
            <Route
              exact
              path="/myResource"
              element={<Protected Component={MyResourcePage} />}
            />

            {/* Representative Pages Routes */}
            <Route
              exact
              path="/local-representatives"
              element={<Protected Component={RepresentativePage} />}
            />
            <Route
              exact
              path="/representative-details"
              element={<Protected Component={RepresentativeDetailsPage} />}
            />
            <Route
              exact
              path="/representative-create"
              element={<Protected Component={RepresentativeCreatePage} />}
            />
            <Route
              exact
              path="/my-representative"
              element={<Protected Component={MyRepresentativePage} />}
            />

            {/* housung address */}
            <Route
              exact
              path="/address-list"
              element={<Protected Component={AddressPage} />}
            />
            <Route
              exact
              path="/address-create"
              element={<Protected Component={AddressCreatePage} />}
            />
            <Route
              exact
              path="/address-create-bulk"
              element={<Protected Component={AddressBulkUploadPage} />}
            />

            {/* Local Business Pages Routes */}
            <Route
              exact
              path="/localBusiness"
              element={<Protected Component={LocalBusinessPage} />}
            />
            <Route
              exact
              path="/localBusiness-details"
              element={<Protected Component={LocalBusinessDetailsPage} />}
            />
            <Route
              exact
              path="/local-business-create"
              element={<Protected Component={LocalBusinessCreatePage} />}
            />
            <Route
              exact
              path="/local-business-subscriptions"
              element={<Protected Component={LocalBusinessSubscriptionPage} />}
            />
            <Route
              exact
              path="/local-business-payment"
              element={<Protected Component={LocalBusinessPaymentPage} />}
            />
            <Route
              exact
              path="/myLocalBusiness"
              element={<Protected Component={myLocalBusinessPage} />}
            />
            <Route
              exact
              path="/localBusiness"
              element={<Protected Component={LocalBusinessPage} />}
            />
            <Route
              exact
              path="/service-request"
              element={<Protected Component={ServiceRequestPage} />}
            />
            <Route
              exact
              path="/service-request-details"
              element={<Protected Component={ServiceRequestDetailsPage} />}
            />

            {/* Matrimonial Pages Routes */}
            <Route
              exact
              path="/matrimonial"
              element={<Protected Component={MatrimonialPage} />}
            />
            <Route
              exact
              path="/matrimonial-details"
              element={<Protected Component={MatrimonialCardDetails} />}
            />
            <Route
              exact
              path="/matrimonial-user-theme/:type/:themenumber"
              element={<Protected Component={DynamicThemePage} />}
            />
            <Route
              exact
              path="/matrimonial-preview-theme/:type/:themenumber"
              element={<Protected Component={MatrimonialPreviewThemePage} />}
            />

            {/* Matrimony pages route */}
            <Route
              exact
              path="/user-matrimony-profile"
              element={<Protected Component={MatrimonyProfilePage} />}
            />
            <Route
              exact
              path="/user-matrimony-profile-create"
              element={<Protected Component={MatrimonyProfileCreatePage} />}
            />
            <Route
              exact
              path="/matrimony-theme"
              element={<Protected Component={MatrimonialThemeSelectPage} />}
            />
            <Route
              exact
              path="/matrimony-my-theme"
              element={<Protected Component={MatrimonialMyThemePage} />}
            />
            <Route
              exact
              path="/matrimony-theme-purchase/:type/:themeId"
              element={<Protected Component={PurchaseThemePage} />}
            />
            <Route
              exact
              path="/stripe/payment/:type/:themeId"
              element={<Protected Component={PaymentPage} />}
            />

            <Route
              exact
              path="/paystation/payment/:type/:themeId"
              element={<Protected Component={PayStationPaymentPage} />}
            />
            {/* Magazine Pages Routes */}
            <Route
              exact
              path="/magazine"
              element={<Protected Component={MagazinePage} />}
            />
            <Route
              exact
              path="/magazine-see-all/:position"
              element={<Protected Component={MagazineSeeAllPostsPage} />}
            />
            <Route
              exact
              path="/magazine-category-details"
              element={<Protected Component={MagazineCategoryDetailsPage} />}
            />
            <Route
              exact
              path="/magazine-category-posts"
              element={<Protected Component={MagazineCatgoryPostsPage} />}
            />
            <Route
              exact
              path="/magazine-demo"
              element={<Protected Component={MagazineDemoPage} />}
            />
            <Route
              exact
              path="/magazine-menu"
              element={<Protected Component={MagazineMenuPage} />}
            />
            <Route
              exact
              path="/magazine-menu-create"
              element={<Protected Component={MagazineMenuCreatePage} />}
            />
            <Route
              exact
              path="/magazine-content"
              element={<Protected Component={MagazineContentPage} />}
            />
            <Route
              exact
              path="/my-magazine-content"
              element={<Protected Component={MyMagazineContentPage} />}
            />
            <Route
              exact
              path="/magazine-content-create"
              element={<Protected Component={MagazineContentCreatePage} />}
            />
            <Route
              exact
              path="/magazine-details"
              element={<Protected Component={MagazineDetailsPage} />}
            />
            <Route
              exact
              path="/magazine-details-demo"
              element={<Protected Component={MagazineDetailsDemoPage} />}
            />

            {/* Event Pages Routes */}
            <Route
              exact
              path="/event"
              element={<Protected Component={EventPage} />}
            />
            <Route
              exact
              path="/event-create"
              element={<Protected Component={EventCreatePage} />}
            />
            <Route
              exact
              path="/event-detail"
              element={<Protected Component={EventDetailsPage} />}
            />
            <Route
              exact
              path="/event-flipbook"
              element={<Protected Component={EventFlipBookPage} />}
            />
            <Route
              exact
              path="/event/invite"
              element={<Protected Component={EventInvitedPeoplePage} />}
            />
            <Route
              exact
              path="/event-banner"
              element={<Protected Component={EventBannrUpdatePage} />}
            />

            {/* Media Pages Routes */}
            <Route
              exact
              path="/media"
              element={<Protected Component={MediaPage} />}
            />
            <Route
              exact
              path="/files/:name"
              element={<Protected Component={MediaNestedPage} />}
            />

            {/* Community setup routes */}
            <Route
              exact
              path="/community-setup"
              element={<Protected Component={CommunitySetUpPage} />}
            />

            {/* Chat Box routes */}
            <Route
              exact
              path="/chat"
              element={<Protected Component={ChatPage} />}
            />

            {/* Group routes */}
            <Route
              exact
              path="/groups"
              element={<Protected Component={GroupMainPage} />}
            />
            <Route
              exact
              path="/my-groups"
              element={<Protected Component={GroupMyPage} />}
            />
            <Route
              exact
              path="/group-store"
              element={<Protected Component={GroupCreatePage} />}
            />
            <Route
              exact
              path="/group-lounge"
              element={<Protected Component={GroupLoungePage} />}
            />

            {/* Others Page */}
            {userDetails && msDetails ? (
              <Route path="*" element={<NotFoundComponent />} />
            ) : (
              <Route path="*" element={<NotFoundPage />} />
            )}
            <Route
              exact
              path="/notification"
              element={<Protected Component={NotificationPage} />}
            />
            <Route
              exact
              path="/notice"
              element={<Protected Component={NoticePage} />}
            />
            {/* <Route exact path="/testpage" element={<TestPage />} /> */}

            {/* Broadcost Pages */}
            <Route
              exact
              path="/broadcast"
              element={<Protected Component={BroadcastHome} />}
            />
            <Route
              exact
              path="/broadcast-category"
              element={<Protected Component={CategoryPage} />}
            />
            <Route
              exact
              path="/broadcast-category/:uuid"
              element={<Protected Component={SingleCategory} />}
            />
            <Route
              exact
              path="/broadcast-category-create"
              element={<Protected Component={CategoryCreatePage} />}
            />
            <Route
              exact
              path="/broadcast-details/:uuid"
              element={<Protected Component={BroadcastDetails} />}
            />
            <Route
              exact
              path="/poll"
              element={<Protected Component={PollHome} />}
            />
            <Route
              exact
              path="/poll/:uuid"
              element={<Protected Component={PollDetailsPage} />}
            />
            <Route
              exact
              path="/poll-create"
              element={<Protected Component={PollCreateFormPage} />}
            />
            <Route
              exact
              path="/poll-update/:uuid"
              element={<Protected Component={PollEditFormPage} />}
            />
             <Route
              exact
              path="/poll-manage"
              element={<Protected Component={PollAdmin} />}
            />
             <Route
              exact
              path="/poll-library"
              element={<Protected Component={PollLibrary} />}
            />
             <Route
              exact
              path="/poll-reward"
              element={<Protected Component={PollRewardClaimCenterPage} />}
            />
             <Route
              exact
              path="/reward-wallet"
              element={<Protected Component={PollRewardWalletPage} />}
            />
            <Route
              exact
              path="/poll-review/:uuid"
              element={<Protected Component={PollResultReviewPage} />}
            />
            <Route
              exact
              path="/look-back"
              element={<Protected Component={LookBack} />}
            />
            <Route
              exact
              path="/broadcast-manage"
              element={<Protected Component={BroadcastAdmin} />}
            />
            <Route
              exact
              path="/broadcast-library"
              element={<Protected Component={BroadcastLibrary} />}
            />
            <Route
              exact
              path="/broadcast-upload"
              element={<Protected Component={BroadcastUploadForm} />}
            />
            <Route
              exact
              path="/broadcast-upload/:uuid"
              element={<Protected Component={BroadcastUploadEditForm} />}
            />
          </Routes>
        </Container>
      </UserContext.Provider>
    </Fragment>
  );
};

export default AppRouter;